import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { IsNullOrUndefined } from '../../helpers/GeneralUtilities';
import { BottomUpTransition } from './BottomUpTransition';

interface FabDevDialogBoxProps {
    closeDialog?: () => void;
    title: string;
    message: string;
  }

export default function FabDevDialogBox (props: FabDevDialogBoxProps) {
    const [isOpen, setIsOpen] = React.useState<boolean>(true)

    const closeDialogBox = () => {
        setIsOpen(false)
        if (!IsNullOrUndefined(props.closeDialog)) {
            props.closeDialog()
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => closeDialogBox()} TransitionComponent={BottomUpTransition}>
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" color="error">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialogBox()} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
        )  
}
