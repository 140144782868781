import * as React from 'react'
import { Container, Typography, Button, Grid, TextField, Stack } from '@mui/material'
import { HTTP } from '../helpers/HTTP'
import { IsStringNullOrWhitespace, IsNullOrUndefined } from '../helpers/GeneralUtilities'
import Item from './others/Item'
import { getAccessToken } from '../auth/AccessTokenHelper'
import { useMsal } from '@azure/msal-react'

export default function StringManipulator() {
    const { instance, accounts, inProgress } = useMsal()
    const [inputData, setInput] = React.useState<string>('')
    const [outputData, setOutput] = React.useState<string>('')
    const [privateKey, setPrivateKey] = React.useState<string>('')
    const [publicKey, setPublicKey] = React.useState<string>('')
    const [hmacKey, setHMACKey] = React.useState<string>('')
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const compressionInput = async (action: string, toBytes: boolean) => {
        try {
            if (!validInputData()) {
                return;
            }
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            var url = `api/string/compression/string/${action}${toBytes ? '/bytes' : ''}`
            const response = await HTTP.PostData<{ value: string }>(url, { value: inputData }, authResult?.accessToken)
            if (IsNullOrUndefined(response) || IsStringNullOrWhitespace(response.value)) {
                setOutput('Unable to compress');
            } else {
                setOutput(response?.value ?? 'No value returned from API');
            }
        } catch (ex: any) {
            handleAPIError(ex);
        } finally {
            setIsLoading(false)
        }
    }

    const hashInput = async () => {
        try {
            if (!validInputData()) {
                return;
            }
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            var url = `api/string/hash/string`
            const response = await HTTP.PostData<{ value: string }>(url, { value: inputData }, authResult?.accessToken)
            if (IsNullOrUndefined(response) || IsStringNullOrWhitespace(response.value)) {
                setOutput('Unable to hash');
            } else {
                setOutput(response?.value ?? 'No value returned from API');
            }
        } catch (ex: any) {
            handleAPIError(ex);
        } finally {
            setIsLoading(false)
        }
    }

    const formatInput = async (type: string) => {
        try {
            if (!validInputData()) {
                return;
            }
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            var url = `api/string/format/${type}`
            const response = await HTTP.PostData<{ value: string }>(url, { value: inputData }, authResult?.accessToken)
            if (IsNullOrUndefined(response) || IsStringNullOrWhitespace(response.value)) {
                setOutput(`Unable to format ${type.toLocaleUpperCase()}`);
            } else {
                setOutput(response?.value ?? 'No value returned from API');
            }
        } catch (ex: any) {
            handleAPIError(ex);
        } finally {
            setIsLoading(false)
        }
    }

    const encryptionInput = async (type: string) => {
        try {
            if (!validInputData()) {
                return;
            }
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            var url = `api/string/encryption/${type}`
            const response = await HTTP.PostData<{ value: string }>(url, { value: inputData, privateKey, publicKey }, authResult?.accessToken)
            if (IsNullOrUndefined(response) || IsStringNullOrWhitespace(response.value)) {
                setOutput(`Unable to ${type.toLocaleUpperCase()} input`);
            } else {
                setOutput(response?.value ?? 'No value returned from API');
            }
        } catch (ex: any) {
            handleAPIError(ex);
        } finally {
            setIsLoading(false)
        }
    }

    const signInput = async () => {
        try {
            if (!validInputData()) {
                return;
            }
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            var url = `api/string/signature/hmac`
            const response = await HTTP.PostData<{ value: string }>(url, { value: inputData, privateKey, publicKey }, authResult?.accessToken)
            if (IsNullOrUndefined(response) || IsStringNullOrWhitespace(response.value)) {
                setOutput(`Unable to sign (HMAC) input`);
            } else {
                setOutput(response?.value ?? 'No value returned from API');
            }
        } catch (ex: any) {
            handleAPIError(ex);
        } finally {
            setIsLoading(false)
        }
    }

    const validInputData = () => {
        if (IsStringNullOrWhitespace(inputData)) {
            setOutput('Type something in input');
            return false;
        }
        return true;
    }

    const handleAPIError = (ex: any) => {
        if (ex.message) {
            setOutput(ex?.message ?? 'No error message returned.');
        } else if (ex.status && ex.statusText) {
            setOutput(`Error during HTTP request: ${ex.status}: ${ex.statusText}`);
        } else {
            setOutput('No error message returned.');
        }
    }

    return (
        <Container sx={{ px: { xs: 0 } }}>
            <Item sx={{ mb: 2 }}>
                <Typography variant="h3" component="div" gutterBottom>
                    String Manipulator
                </Typography>
            </Item>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Item>
                        <TextField sx={{ m: 1, width: '95%' }} multiline rows={20} disabled={isLoading} variant="filled" label="Input" value={inputData} onChange={elem => setInput(elem.target.value)} />
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Stack>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => compressionInput('compress', false)}>
                            Compress
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => compressionInput('compress', true)}>
                            Compress (bytes)
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => compressionInput('decompress', false)}>
                            Decompress
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={hashInput}>
                            Hash
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => formatInput('json')}>
                            Format JSON
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => formatInput('xml')}>
                            Format XML
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => formatInput('sql')}>
                            Format SQL
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => encryptionInput('encrypt')}>
                            Encrypt
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={() => encryptionInput('decrypt')}>
                            Decrypt
                        </Button>
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={signInput}>
                            Sign (HMAC)
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={5}>
                    <Item>
                        <TextField sx={{ m: 1, width: '95%' }} inputProps={{ readOnly: true }} multiline rows={20} disabled={isLoading} variant="filled" label="Output" value={outputData} onChange={elem => setOutput(elem.target.value)} />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <TextField sx={{ m: 1, width: '95%' }} disabled={isLoading} variant="filled" label="HMAC key" value={hmacKey} onChange={elem => setHMACKey(elem.target.value)} />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <TextField sx={{ m: 1, width: '95%' }} multiline disabled={isLoading} variant="filled" label="Public key" value={publicKey} onChange={elem => setPublicKey(elem.target.value)} />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <TextField sx={{ m: 1, width: '95%' }} multiline disabled={isLoading} variant="filled" label="Private key" value={privateKey} onChange={elem => setPrivateKey(elem.target.value)} />
                    </Item>
                </Grid>
            </Grid>
        </Container>
    )
}