import * as React from 'react'
import { IMaskInput } from 'react-imask'

interface CpfInputFieldProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

const CpfInputField = React.forwardRef<HTMLElement, CpfInputFieldProps>(function CpfInputField(props, ref) {
    const { onChange, ...other } = props
    return (
        <IMaskInput
            {...other}
            //@ts-ignore
            mask='000.000.000-00'
            lazy
            unmask={true}
            inputRef={elem => ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    )
})

export default CpfInputField
