import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { useState } from 'react'
import { Button } from '@mui/material'

const PdfViewerUnit = ({ pdfFile }: { pdfFile: Uint8Array }) => {
    const [numPages, setNumPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages)
    }

    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset)
    }

    return (
        <>
            <div>
                <p>
                    Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                </p>
                <Button sx={{ marginRight: 2 }} variant="contained" color="success" disabled={pageNumber <= 1} onClick={elem => changePage(-1)}>
                    Anterior
                </Button>
                <Button variant="contained" color="success" disabled={pageNumber >= numPages} onClick={elem => changePage(1)}>
                    Próxima
                </Button>
            </div>
            <br />
            <Document file={{ data: pdfFile }} className="inline-block" onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
        </>
    )
}
export default PdfViewerUnit // eslint-disable-line @typescript-eslint/no-explicit-any
