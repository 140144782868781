import { loginRequest } from './authConfig'
import { AccountInfo, AuthenticationResult, BrowserAuthError, InteractionStatus, IPublicClientApplication } from '@azure/msal-browser'
import { IsNullOrUndefined } from '../helpers/GeneralUtilities';

export async function getAccessToken(instance: IPublicClientApplication, accounts: AccountInfo[], inProgress: InteractionStatus): Promise<AuthenticationResult | null> {
    const request = {
        ...loginRequest,
        account: accounts[0],
    }
    var result: AuthenticationResult | null = null;
    try {
        result = await instance.acquireTokenSilent(request)
    } catch (ex: any) {
        if (inProgress === InteractionStatus.None) {
            result = await instance.acquireTokenPopup(request)
        }
    }
    if (IsNullOrUndefined(result)) {
        console.error('Authentication result not found after trying to aquire token.')
    }
    return result
}
