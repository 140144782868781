import * as React from 'react'
import { AppBar, Button, CircularProgress, Container, Dialog, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import Item from '../others/Item'
import { BottomUpTransition } from '../others/BottomUpTransition'
import CloseIcon from '@mui/icons-material/Close'

interface DocxViewerDialogInputProps {
    ContractHash: string,
    ContractName: string,
    onClose: () => void
}

export default function ReceiptUnit({ ContractHash, ContractName, onClose }: DocxViewerDialogInputProps) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [canRenderPdf, setCanRenderPdf] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [pdfBytes, setPdfBytes] = React.useState<Uint8Array>(new Uint8Array())

    var spinner = <CircularProgress color="success" />

    return (
        <Dialog fullScreen open={true} onClose={onClose} TransitionComponent={BottomUpTransition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Recibo do Contrato de {ContractName}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={onClose}>
                        fechar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container sx={{ mt: 2 }}>
                <Stack spacing={2}>
                    <Item>
                            <Typography>Ainda não ta pronto, vai embora</Typography>
                    </Item>
                </Stack>
            </Container>
        </Dialog>
    )
}
