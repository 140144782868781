import createDOMPurify from 'dompurify'
import * as React from 'react'
import { Button, TextField, MenuItem, Table, TableRow, TableCell, TableContainer, TableHead, Paper, TableBody, Checkbox, LinearProgress, Typography, Container, Stack } from '@mui/material'
import { IsNullOrUndefined, IsStringNullOrWhitespace } from '../helpers/GeneralUtilities'
import Item from './others/Item'
import { useMsal } from '@azure/msal-react'
import { getAccessToken } from '../auth/AccessTokenHelper'
import { HTTP } from '../helpers/HTTP'

export default function DevopsBot() {
    const { instance, accounts, inProgress } = useMsal()
    const DOMPurify = createDOMPurify(window)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [message, setMessage] = React.useState<string>('')
    const [pat, setPat] = React.useState<string>('')
    const [organization, setOrganization] = React.useState<string>('')
    const [hoursPerStoryPoint, setHoursPerStoryPoint] = React.useState<number>(6.0)
    const [projectsDropdownIsEnabled, setProjectsDropdownIsEnabled] = React.useState<boolean>(false)
    const [teamsDropdownIsEnabled, setTeamsDropdownIsEnabled] = React.useState<boolean>(false)
    const [iterationsDropdownIsEnabled, setIterationsDropdownIsEnabled] = React.useState<boolean>(false)
    const [projects, setProjects] = React.useState<DevopsBotProject[]>([])
    const [teams, setTeams] = React.useState<DevopsBotTeam[]>([])
    const [iterations, setIterations] = React.useState<DevopsBotIterations | null>(null)
    const [templates, setTemplates] = React.useState<DevopsTaskTemplate[]>([])
    const [userStories, setUserStories] = React.useState<DevopsUserStories[]>([])
    const [selectedProject, setSelectedProject] = React.useState<DevopsBotProject | null>(null)
    const [selectedTeam, setSelectedTeam] = React.useState<DevopsBotTeam | null>(null)
    const [selectedIteration, setSelectedIteration] = React.useState<TeamIterationValues | null>(null)

    const spinner = <LinearProgress color="success" />

    const renderDropdownItemsProjects = () => {
        if (projects.length === 0) {
            return <MenuItem>No projects</MenuItem>
        }
        return projects.map((prj: DevopsBotProject, i: number) => (
            <MenuItem key={`projectsDropdown${i}`} onClick={() => requestTeams(prj.id ?? '')}>
                {prj.title}
            </MenuItem>
        ))
    }

    const requestTeams = async (projectId: string) => {
        if (IsStringNullOrWhitespace(projectId)) {
            setMessage('Project ID not found on selected item')
            return
        }
        if (IsStringNullOrWhitespace(pat)) {
            setMessage('Personal Access Token not set.')
            return
        }
        const url = `devops/${pat}/projects/${projectId}/teams`
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const returnTeams = await HTTP.GetData<DevopsBotTeam[]>(url, authResult?.accessToken)
            if (IsNullOrUndefined(returnTeams)) {
                setMessage('Teams not found for project.')
                return
            }
            const selectedProject = projects.find(proj => proj.id === projectId) ?? null
            setTeams(returnTeams ?? [])
            setTeamsDropdownIsEnabled(teams.length > 0)
            setSelectedProject(selectedProject)
            clearInterface('ITERATIONS')
        } catch (ex: any) {
            setMessage(`${url} ${ex.message}`)
        } finally {
            setIsLoading(false)
        }
    }

    const renderDropdownItemsTeams = () => {
        if (teams.length === 0) {
            return <MenuItem>No teams</MenuItem>
        }
        return teams.map((tm: DevopsBotTeam, i: number) => (
            <MenuItem key={`teamsDropdown${i}`} onClick={() => requestTeamIterations(tm.id ?? '')}>
                {tm.name}
            </MenuItem>
        ))
    }

    const requestTeamIterations = async (teamId: string) => {
        const selectedProjectId = selectedProject?.id
        if (IsStringNullOrWhitespace(selectedProjectId)) {
            setMessage('Project ID not found on selected item')
            return
        }
        if (IsStringNullOrWhitespace(teamId)) {
            setMessage('Team ID not found on selected item')
            return
        }
        if (IsStringNullOrWhitespace(organization)) {
            setMessage('Organization must be set')
            return
        }
        if (IsStringNullOrWhitespace(pat)) {
            setMessage('Personal Access Token not set.')
            return
        }
        var url = `devops/${pat}/projects/${selectedProjectId}/teams/${teamId}/iterations/${organization}`
        var returnObj: { iterations: DevopsBotIterations | undefined; templates: DevopsTaskTemplate[] } = { iterations: undefined, templates: [] }
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            returnObj.iterations = await HTTP.GetData<DevopsBotIterations>(url, authResult?.accessToken)
            if (IsNullOrUndefined(returnObj.iterations)) {
                setMessage('Team iterations not found.')
                return
            }
            url = `devops/${pat}/projects/${selectedProjectId}/teams/${teamId}/templates`
            returnObj.templates = (await HTTP.GetData<DevopsTaskTemplate[]>(url, authResult?.accessToken)) ?? []
            if (IsNullOrUndefined(returnObj.templates)) {
                setMessage('Team templates not found.')
                return
            }

            const selectedTeam = teams.find(tm => tm.id === teamId) ?? null
            setIterations(returnObj.iterations)
            setTemplates(returnObj.templates)
            setIterationsDropdownIsEnabled(templates.length > 0)
            setSelectedTeam(selectedTeam)
            clearInterface('USERSTORIES')
        } catch (ex: any) {
            setMessage(`${url} ${ex.message}`)
        } finally {
            setIsLoading(false)
        }
    }

    const renderTeamTemplates = () => {
        if ((templates?.length ?? 0) === 0) {
            return (
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>No task templates</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            )
        }
        var sorted = [...templates].sort((a, b) => a.name.localeCompare(b.name))
        return sorted.map((tm: DevopsTaskTemplate, i: number) => (
            <TableRow key={`templatesList${i}`}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                    <Checkbox aria-label="Checkbox for creating a task" checked={tm.createTask} onChange={elem => toggleTaskTemplateToCreate(elem.target.checked, tm.id)} />
                </TableCell>
                <TableCell>{tm.name}</TableCell>
                <TableCell>{tm.originalEstimate}</TableCell>
                <TableCell>{tm.remainingWork}</TableCell>
                <TableCell>{tm.completedWork}</TableCell>
            </TableRow>
        ))
    }

    const toggleTaskTemplateToCreate = (toAdd: boolean, taskId: string) => {
        var taskFromList = templates.find(tm => tm.id === taskId)
        if (IsNullOrUndefined(taskFromList)) {
            setMessage('Selected template was not found')
            return
        }
        const allTasks = [...templates]
        allTasks.forEach(task => {
            if (taskId === task.id) {
                task.createTask = toAdd
            }
        })
        setTemplates(allTasks)
    }

    const renderDropdownItemsIterations = () => {
        if ((iterations?.value.length ?? 0) === 0) {
            return <MenuItem>No iterations</MenuItem>
        }
        return iterations?.value.map((it: TeamIterationValues, i: number) => <MenuItem key={`iterationsDropdown${i}`} onClick={() => requestUserStories(it.path ?? '')}>{`${it.name} (${it.attributes.timeFrame})`}</MenuItem>)
    }

    const requestUserStories = async (iterationPath: string) => {
        if (IsNullOrUndefined(selectedProject)) {
            setMessage('Project not found on selected item')
            return
        }
        if (IsStringNullOrWhitespace(iterationPath)) {
            setMessage('Iteration Path not found on selected item')
            return
        }
        if (IsStringNullOrWhitespace(pat)) {
            setMessage('Personal Access Token not set')
            return
        }
        var url = `devops/${pat}/projects/${selectedProject?.id}/${encodeURI(selectedProject?.title ?? '')}/userstories/${encodeURI(iterationPath)}`
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const returnObj = await HTTP.GetData<DevopsUserStories[]>(url, authResult?.accessToken)
            if (IsNullOrUndefined(returnObj)) {
                setMessage('User stories not found.')
                return
            }
            const selectedIteration = iterations?.value.find(it => it.path === iterationPath) ?? null
            setUserStories(returnObj ?? [])
            setSelectedIteration(selectedIteration)
            setMessage('')
        } catch (ex: any) {
            setMessage(`${url} ${ex.message}`)
        } finally {
            setIsLoading(false)
        }
    }

    const renderIterationUserStories = () => {
        if ((userStories?.length ?? 0) === 0) {
            return (
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>No user stories</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            )
        }
        return userStories.map((us: DevopsUserStories, i: number) => (
            <TableRow key={`templatesList${i}`}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                    <Checkbox aria-label="Checkbox for creating a task is a user story" checked={us.createTask} onChange={elem => toggleUserStoryToCreate(elem.target.checked, us.id)} />
                </TableCell>
                <TableCell>{us.title}</TableCell>
                <TableCell>{us.state}</TableCell>
                <TableCell dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(us.description) }}></TableCell>
                <TableCell>{us.storyPoints}</TableCell>
                <TableCell>{(us.tasks?.length ?? 0) > 0 ? 'Yes' : 'No'}</TableCell>
            </TableRow>
        ))
    }

    const toggleUserStoryToCreate = (toAdd: boolean, usId: number) => {
        var usFromList = userStories.find(us => us.id === usId) ?? null
        if (IsNullOrUndefined(usFromList)) {
            setMessage('Selected user story was not found')
            return
        }
        const allUs = [...userStories]
        allUs.forEach(us => {
            if (usId === us.id) {
                us.createTask = toAdd
            }
        })
        setUserStories(allUs)
        setMessage('')
    }

    const requestProjects = async () => {
        if (IsStringNullOrWhitespace(pat)) {
            setMessage('Personal Access Token not set')
            return;
        }
        const url = `devops/${pat}/projects`;
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const returnObj = await HTTP.GetData<DevopsBotProject[]>(url, authResult?.accessToken);
            if (IsNullOrUndefined(returnObj)) {
                setMessage('Team projects not found.')
                return
            }
            setProjects(returnObj ?? [])
            setProjectsDropdownIsEnabled(projects.length > 0)
            setMessage('')
        }
        catch (ex: any) {
            setMessage(`${url} ${ex.message}`)
        }
        finally {
            setIsLoading(false)
            clearInterface('TEAMS')
        }
    }

    const createSelectedTasks = async () => {
        if (IsStringNullOrWhitespace(pat)) {
            setMessage('Personal Access Token not set')
            return;
        }
        if (IsNullOrUndefined(selectedProject)) {
            setMessage('Project not found on selected item')
            return;
        }
        const selectedTeamId = selectedTeam?.id;
        if (IsStringNullOrWhitespace(selectedTeamId)) {
            setMessage('Team ID not found on selected item')
            return;
        }
        const iterationPath = selectedIteration?.path;
        if (IsStringNullOrWhitespace(iterationPath)) {
            setMessage('Iteration Path not found on selected item')
            return;
        }
        if (hoursPerStoryPoint < 0) {
            setMessage('Hours per story point cannot be less than zero.')
            return;
        }
        const tasks = templates.filter(t => t.createTask);
        const us = userStories.filter(us1 => us1.createTask).map(us1 => us1.id);
        const body = { tasks, userStoriesIds: us, hoursPerStoryPoint };
        if (IsNullOrUndefined(body)) {
            setMessage('You need to select tasks and US to create the tasks in.')
            return;
        }
        const url = `devops/${pat}/projects/${selectedProject?.id}/teams/${selectedTeamId}/${encodeURI(iterationPath)}/create`;
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const returnObj = await HTTP.PostData<boolean>(url, body, authResult?.accessToken);
            if (returnObj) {
                setMessage('Tasks were successfully created')
            } else {
                setMessage('An unknown error happened while trying to create tasks in user stories. Reload the stories and try again.')
            }
        }
        catch (ex: any) {
            setMessage(`${url} ${ex.message}`)
        }
        finally {
            setIsLoading(false)
        }
    }

    const clearInterface = (portion: string) => {
        switch (portion) {
            case 'PROJECTS':
                setMessage('')
                setProjects([])
                setSelectedProject(null)
                setProjectsDropdownIsEnabled(false)
                setTeams([])
                setSelectedTeam(null)
                setTeamsDropdownIsEnabled(false)
                setTemplates([])
                setIterations(null)
                setSelectedIteration(null)
                setIterationsDropdownIsEnabled(false)
                setUserStories([])
                break
            case 'TEAMS':
                setMessage('')
                setTeams([])
                setSelectedTeam(null)
                setTeamsDropdownIsEnabled(false)
                setTemplates([])
                setIterations(null)
                setSelectedIteration(null)
                setIterationsDropdownIsEnabled(false)
                setUserStories([])
                break
            case 'ITERATIONS':
                setMessage('')
                setIterations(null)
                setSelectedIteration(null)
                setIterationsDropdownIsEnabled(false)
                setUserStories([])
                break
            case 'USERSTORIES':
                setMessage('')
                setUserStories([])
                break
            case 'ALL':
            default:
                setMessage('')
                setOrganization('')
                setHoursPerStoryPoint(6.0)
                setProjectsDropdownIsEnabled(false)
                setTeamsDropdownIsEnabled(false)
                setIterationsDropdownIsEnabled(false)
                setProjects([])
                setTeams([])
                setIterations(null)
                setTemplates([])
                setUserStories([])
                setSelectedProject(null)
                setSelectedTeam(null)
                setSelectedIteration(null)
                break
        }
    }

    return (
        <Container sx={{ mb: 2 }}>
            <Item sx={{ mb: 2 }}>
                <Typography variant="h3" component="div" gutterBottom>
                    Devops Bot
                </Typography>
            </Item>
            <Stack spacing={2}>
                <Item>
                    <Stack>
                        <TextField sx={{ m: 1 }} variant="filled" label="PAT" value={pat} onChange={elem => setPat(elem.target.value)} disabled={isLoading} />
                        <TextField sx={{ m: 1 }} variant="filled" label="Organization" value={organization} onChange={elem => setOrganization(elem.target.value)} disabled={isLoading} />
                        <TextField sx={{ m: 1 }} variant="filled" label="Hours per story point" onChange={elem => setHoursPerStoryPoint(Number(elem.target.value))} value={hoursPerStoryPoint} disabled={isLoading} />
                    </Stack>
                </Item>
                {!IsStringNullOrWhitespace(message) && <Item>{message}</Item>}
                <Item>
                    <Button color="primary" className="mr-2" disabled={isLoading} onClick={() => requestProjects()}>
                        Load projects
                    </Button>
                </Item>
                <Item>
                    <Stack>
                        <TextField sx={{ m: 1 }} variant="filled" label={selectedProject?.title ?? 'Projects'} disabled={isLoading || !projectsDropdownIsEnabled} select>
                            {renderDropdownItemsProjects()}
                        </TextField>
                        <TextField sx={{ m: 1 }} variant="filled" label={selectedTeam?.name ?? 'Teams'} disabled={isLoading || !teamsDropdownIsEnabled} select>
                            {renderDropdownItemsTeams()}
                        </TextField>
                        <TextField sx={{ m: 1 }} variant="filled" label={selectedIteration?.name ?? 'Iterations'} disabled={isLoading || !iterationsDropdownIsEnabled} select>
                            {renderDropdownItemsIterations()}
                        </TextField>
                    </Stack>
                </Item>
                {isLoading && <Item>{spinner}</Item>}
                <Item>
                    <Button color="success" sx={{ mr: 2 }} disabled={isLoading || !templates.some(tm => tm.createTask) || !userStories.some(us => us.createTask)} onClick={() => createSelectedTasks()}>
                        Create selected tasks
                    </Button>
                    <Button color="error" sx={{ ml: 2 }} disabled={isLoading} onClick={() => clearInterface('ALL')}>
                        Clear
                    </Button>
                </Item>
                <TableContainer component={Paper}>
                    <Table aria-label="US table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Create</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Original Estimate</TableCell>
                                <TableCell align="center">Remaining Work</TableCell>
                                <TableCell align="center">Completed Work</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderTeamTemplates()}</TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper}>
                    <Table aria-label="US table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Create tasks</TableCell>
                                <TableCell align="center">Title</TableCell>
                                <TableCell align="center">State</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Story points</TableCell>
                                <TableCell align="center">Has tasks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderIterationUserStories()}</TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Container>
    )
}

interface DevopsBotProject {
    id: string
    title: string
}

interface DevopsBotTeam {
    id: string
    name: string
    description: string
    url: string
}

interface DevopsBotIterations {
    count: number
    value: TeamIterationValues[]
}

interface TeamIterationValues {
    id: string
    name: string
    path: string
    url: string
    attributes: TeamIterationAttributes
}

interface TeamIterationAttributes {
    startDate: Date | undefined
    finishDate: Date | undefined
    timeFrame: string
}

interface DevopsTaskTemplate {
    name: string
    id: string
    originalEstimate: number
    remainingWork: number
    completedWork: number
    createTask: boolean
}

interface DevopsTaskSummary {
    id: number
    title: string
    state: string
}

interface DevopsUserStories {
    id: number
    storyPoints: number
    title: string
    state: string
    description: string
    createTask: boolean
    tasks: DevopsTaskSummary[]
}
