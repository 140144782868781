import * as React from 'react'
import ReactJson from 'react-json-view'
import { Button } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import { IsNullOrUndefined, IsStringNullOrWhitespace } from '../helpers/GeneralUtilities'
import { HTTP } from '../helpers/HTTP'
import FabDevDialogBox from './others/FabDevDialogBox'
import { getAccessToken } from '../auth/AccessTokenHelper'
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from 'redux-signalr'
import { Guid } from 'guid-typescript'
import { useEffect } from 'react'

interface AdyenNotification {
    json: string
    arrivedAt: Date
    index: Number
    sent: boolean
}

export default function AdyenNotifications() {
    const [connection, setConnection] = React.useState<HubConnection | null>(null)
    const { instance, accounts, inProgress } = useMsal()
    const [notifications, setNotifications] = React.useState<AdyenNotification[]>([])
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [signalRConnected, setSignalRConnected] = React.useState<boolean>(false)
    const [testNotification, setTestNotification] = React.useState<{ message: string }>({ message: '' })

    useEffect(() => {
        const newConnection = new HubConnectionBuilder().configureLogging(LogLevel.Warning).withUrl('adyenhub').withAutomaticReconnect().build()
        setConnection(newConnection)
    }, [])

    /*useEffect(() => {
        if (!IsNullOrUndefined(connection)) {
            connection
                .start()
                .then(() => {
                    setSignalRConnected(true)
                    connection.on('ReceiveNotification', (message: string) => {
                        addNotification(decodeURIComponent(JSON.stringify(message)))
                    })
                })
                .catch(e => console.log('Connection failed: ', e))
        }
    }, [connection])*/

    const requestTest = async () => {
        const url = `adyen/test/${Guid.create().toString()}`
        try {
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const returnString = await HTTP.GetData<{ message: string }>(url, authResult?.accessToken)
            setTestNotification(returnString ?? { message: 'No message returned' })
            addNotification(decodeURIComponent(JSON.stringify(returnString)))
        } catch (ex: any) {
            setErrorMessage(`${url} ${ex.message}`)
        }
    }

    const requestSignalRConnection = async () => {
        try {
            if (!IsNullOrUndefined(connection)) {
                if (connection.state !== HubConnectionState.Connected) {
                    await connection.start()
                    connection.on('ReceiveNotification', (message: string) => {
                        addNotification(decodeURIComponent(JSON.stringify(message)))
                    })
                    setSignalRConnected(true)
                }
            } else {
                setErrorMessage('Unbale to connect. connection is null')
            }
        } catch (ex: any) {
            setSignalRConnected(false)
            setErrorMessage(ex.message)
        }
    }

    const addNotification = (json: string) => {
        const notification: AdyenNotification = {
            json,
            arrivedAt: new Date(),
            index: notifications.length,
            sent: false,
        }
        var newNotifications = [...notifications]
        if (newNotifications.length >= 150) {
            newNotifications = newNotifications.splice(99, 49)
        }
        setNotifications([notification, ...newNotifications])
    }

    const requestNotificationsClear = () => {
        setNotifications([])
    }

    const renderNotifications = () => {
        return notifications.map((notification: AdyenNotification, i: number) => (
            <div className="row mb-2" key={i}>
                <div className="col-3" key={'index' + i}>
                    {notification.index}: {notification.arrivedAt.toLocaleString()}
                </div>
                <div className="col-7" key={'json' + i}>
                    <ReactJson key={'reactjson' + i} theme="chalk" displayDataTypes={false} collapsed={true} name={false} src={JSON.parse(notification.json)} />
                </div>
            </div>
        ))
    }

    return (
        <div className="container px-4 py-2">
            <div className="rounded-rectangle-top px-4 py-2">
                <h1 className="my-1">Adyen Notifications</h1>
            </div>
            <div className="rounded-rectangle-bottom px-4 py-2">
                <div className="row">
                    <p className="col-12">Foram recebidas {notifications.length} notificações.</p>
                    <p className="col-12">
                        Status da conexão: {signalRConnected ? 'Conectado' : 'Desconectado'} (Test msg: {testNotification.message}).
                    </p>
                    <p className="col-12">Mensagem de erro: {errorMessage}</p>
                </div>
                <div className="row col-12">
                    <Button color="success" sx={{ mr: 2 }} onClick={() => requestTest()}>
                        Test
                    </Button>
                    <Button color="success" sx={{ mr: 2 }} onClick={() => requestNotificationsClear()} disabled={notifications.length == 0}>
                        Clear
                    </Button>
                    <Button color="success" onClick={() => requestSignalRConnection()} disabled={signalRConnected}>
                        Connect
                    </Button>
                </div>
                {renderNotifications()}
                {!IsStringNullOrWhitespace(errorMessage) && <FabDevDialogBox title="Erro durante requisição" message={errorMessage} />}
            </div>
        </div>
    )
}
