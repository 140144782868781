import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import * as ReactDOM from 'react-dom'
import App from './App'
import unregister from './registerServiceWorker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './auth/authConfig'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter } from "react-router-dom";

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string
//registerServiceWorker();
const rootElement = document.getElementById('root')
const msalInstance = new PublicClientApplication(msalConfig)
unregister()
ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'pt-BR'}>
                <ThemeProvider
                    theme={createTheme({
                        palette: {
                            mode: 'dark',
                        },
                    })}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </LocalizationProvider>
        </BrowserRouter>
    </MsalProvider>,
    rootElement
)
