import { Switch, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home'
import { PrivateRoute } from './components/PrivateRoute'
import AdyenNotifications from './components/AdyenNotifications'
import DevopsBot from './components/DevopsBot'
import CasaraoGiardino from './components/CasaraoGiardino'
import StringManipulator from './components/StringManipulator'
import SwaggerDoc from './components/SwaggerDoc'

import './custom.css'
import FitApp from './components/fitapp/FitApp'

export default () => {
    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <PrivateRoute path="/adyennotifications" component={AdyenNotifications} />
                <PrivateRoute path="/devopsbot" component={DevopsBot} />
                <PrivateRoute path="/giardino" component={CasaraoGiardino} />
                <PrivateRoute path="/strings" component={StringManipulator} />
                <PrivateRoute path="/swaggerdoc" component={SwaggerDoc} />
                <PrivateRoute path="/fitapp" component={FitApp} />
            </Switch>
        </Layout>
    )
}
