import { CircularProgress } from '@mui/material'
import { Route } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import Home from './Home'

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
    const { instance, accounts, inProgress } = useMsal()
    const isAuthenticated = useIsAuthenticated()

    if (inProgress != InteractionStatus.None) {
        return <CircularProgress color="inherit" />
    }
    if (!isAuthenticated) {
        return (<Route {...rest} component={Home} ></Route>)
    }

    return (
        <Route
            {...rest}
            render={props => (
                <>
                    <AuthenticatedTemplate>
                        <Component {...props} />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <p>Realize seu login</p>
                    </UnauthenticatedTemplate>
                </>
            )}
        />
    )
}