import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { loginRequest } from '../auth/authConfig'
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography, Link, MenuList, Box, Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { IsNullOrUndefined, IsStringNullOrWhitespace } from '../helpers/GeneralUtilities'
import { BrowserAuthError, IPublicClientApplication } from '@azure/msal-browser'
import FabDevDialogBox from './others/FabDevDialogBox'
import { getAccessToken } from '../auth/AccessTokenHelper'

export const NavMenu = () => {
    const isAuthenticated = useIsAuthenticated()
    const { instance, accounts, inProgress } = useMsal()
    const name = (accounts[0] && accounts[0].name) ?? ''
    const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null)
    const [anchorElNavMenu, setAnchorElNavMenu] = React.useState<null | HTMLElement>(null)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [accessToken, setAccessToken] = React.useState<string>('')

    React.useEffect(() => {
        let isSubscribed = true
        // declare the async data fetching function
        const fetchAccessToken = async () => {
            // get the data
            var authResult = await getAccessToken(instance, accounts, inProgress)
            // set state with the result if `isSubscribed` is true
            if (isSubscribed) {
                if (!IsNullOrUndefined(authResult) && !IsStringNullOrWhitespace(authResult.accessToken)) {
                    setAccessToken(authResult.accessToken)
                } else {
                    handleLogin(instance)
                }
            }
        }

        fetchAccessToken().catch((ex: any) => setErrorMessage(ex.message))
        // cancel any future `setData`
        return
    }, [isAuthenticated])

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElProfile(event.currentTarget)
    }

    const handleProfileMenuClose = () => {
        setAnchorElProfile(null)
    }

    const handleNavMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNavMenu(event.currentTarget)
    }

    const handleNavMenuClose = () => {
        setAnchorElNavMenu(null)
    }

    var errorDialog = <></>
    if (!IsStringNullOrWhitespace(errorMessage)) {
        errorDialog = <FabDevDialogBox title="Erro durante login" message={errorMessage} />
    }

    function handleLogin(instance: IPublicClientApplication) {
        instance.loginPopup(loginRequest).catch(handleBrowserAuthError)
    }

    function handleLogout(instance: IPublicClientApplication) {
        instance.logoutPopup().catch(handleBrowserAuthError)
    }

    function handleBrowserAuthError(error: BrowserAuthError) {
        if (error.errorCode !== 'user_cancelled') {
            setErrorMessage(error.errorMessage)
            console.error(error)
        }
    }

    return (
        <AppBar position="static" sx={{ mb: 2 }}>
            <Toolbar>
                {isAuthenticated && (
                    <>
                        <IconButton size="large" aria-label="Navigation menu" aria-controls="menu-navbar" aria-haspopup="true" onClick={handleNavMenuOpen} color="inherit" sx={{ mr: 2 }}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-navbar"
                            anchorEl={anchorElNavMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNavMenu)}
                            onClose={handleNavMenuClose}>
                            <MenuList>
                                <Link onClick={handleNavMenuClose} component={RouterLink} to="/" underline="none">
                                    <MenuItem>Home</MenuItem>
                                </Link>
                                <Link onClick={handleNavMenuClose} component={RouterLink} to="/adyennotifications" underline="none">
                                    <MenuItem>Adyen</MenuItem>
                                </Link>
                                <Link onClick={handleNavMenuClose} component={RouterLink} to="/devopsbot" underline="none">
                                    <MenuItem>Devops Bot</MenuItem>
                                </Link>
                                <Link onClick={handleNavMenuClose} component={RouterLink} to="/giardino" underline="none">
                                    <MenuItem>Casarão Giardino</MenuItem>
                                </Link>
                                <Link onClick={handleNavMenuClose} component={RouterLink} to="/strings" underline="none">
                                    <MenuItem>String Manipulator</MenuItem>
                                </Link>
                                <Link onClick={handleNavMenuClose} component={RouterLink} to="/swaggerdoc" underline="none">
                                    <MenuItem>Swagger Doc</MenuItem>
                                </Link>
                                <Link onClick={handleNavMenuClose} component={RouterLink} to="/fitapp" underline="none">
                                    <MenuItem>FitApp</MenuItem>
                                </Link>
                            </MenuList>
                        </Menu>
                    </>
                )}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    FabDev
                </Typography>
                {errorDialog}
                {isAuthenticated ? (
                    <Box>
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
                            <Typography variant="body1" sx={{ mr: 2 }}>
                                {name}
                            </Typography>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-profile-appbar"
                            anchorEl={anchorElProfile}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElProfile)}
                            onClose={handleProfileMenuClose}>
                            <Button variant="contained" color="success" onClick={() => handleLogout(instance)}>
                                Logout
                            </Button>
                        </Menu>
                    </Box>
                ) : (
                    <Button variant="contained" color="success" onClick={() => handleLogin(instance)}>
                        Login
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    )
}
