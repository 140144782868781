import * as React from 'react'
import { base64ToArrayBuffer, IsNullOrUndefined, IsStringNullOrWhitespace } from '../../helpers/GeneralUtilities'
import { AppBar, Button, Container, Dialog, IconButton, LinearProgress, Stack, Toolbar, Typography } from '@mui/material'
import Item from '../others/Item'
import PdfViewerUnit from '../PdfViewerUnit'
import { BottomUpTransition } from '../others/BottomUpTransition'
import CloseIcon from '@mui/icons-material/Close'
import { GiardinoPdfApiReturn } from '../../interfaces/Interfaces'
import { HTTP } from '../../helpers/HTTP'
import { useMsal } from '@azure/msal-react'
import { getAccessToken } from '../../auth/AccessTokenHelper'
import FabDevDialogBox from '../others/FabDevDialogBox'

interface PdfViewerDialogInputProps {
    ContractHash: string
    ContractName: string
    onClose: () => void
}

export default function PdfViewerDialog({ ContractHash, ContractName, onClose }: PdfViewerDialogInputProps) {
    const { instance, accounts, inProgress } = useMsal()
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [canRenderPdf, setCanRenderPdf] = React.useState<boolean>(false)
    const [pdfBytes, setPdfBytes] = React.useState<Uint8Array>(new Uint8Array())
    const [shareData, setShareData] = React.useState<ShareData>({ title: 'Contrato Giardino', text: 'Acesse seu contrato com o Casarão Giardino agora mesmo' })

    const GetPdfBytes = async () => {
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const pdfResponse = await HTTP.GetData<GiardinoPdfApiReturn>(`api/giardino/contract/${ContractHash}/pdf`, authResult?.accessToken)
            if (IsNullOrUndefined(pdfResponse)) {
                setErrorMessage('Não foi possível ler o contrato. Tente novamente.')
            } else if (!pdfResponse.success) {
                setErrorMessage(pdfResponse.message)
            } else {
                var array = base64ToArrayBuffer(pdfResponse.pdfFile)
                setPdfBytes(array)
                setCanRenderPdf(true)
                setShareData({
                    title: 'Contrato Giardino',
                    text: 'Acesse seu contrato com o Casarão Giardino agora mesmo',
                    files: [new File([array], `Contrato - ${ContractName}.pdf`, { type: 'application/pdf' })],
                })
            }
        } catch (ex: any) {
            setErrorMessage(ex.message)
        } finally {
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        GetPdfBytes()
    }, [])

    return (
        <Dialog fullScreen open={true} onClose={onClose} TransitionComponent={BottomUpTransition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Contrato de {ContractName}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={onClose}>
                        fechar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container sx={{ mt: 2 }}>
                {isLoading ? (
                    <LinearProgress color="success" />
                ) : (
                    <>
                        {!IsStringNullOrWhitespace(errorMessage) && <FabDevDialogBox title="Erro durante requisição" message={errorMessage} />}
                        <Stack spacing={2}>
                            {canRenderPdf && !isLoading && (
                                <Item>
                                    <Button variant="contained" color="success" disabled={!canRenderPdf || isLoading} download={`contrato-${ContractName}.pdf`} href={URL.createObjectURL(new Blob([pdfBytes]))}>
                                        Download
                                    </Button>
                                </Item>
                            )}
                            {navigator.canShare && navigator.canShare(shareData) && (
                                <Item>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        disabled={!canRenderPdf || isLoading}
                                        onClick={async () => {
                                            await navigator.share(shareData)
                                        }}>
                                        Compartilhar
                                    </Button>
                                </Item>
                            )}
                            <Item>
                                <PdfViewerUnit pdfFile={pdfBytes} />
                            </Item>
                        </Stack>
                    </>
                )}
            </Container>
        </Dialog>
    )
}
