import * as React from 'react'
import { Container, Typography, Button, Stack, AppBar, Dialog, IconButton, Toolbar, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import NewContractUnit from './giardino/NewContractUnit'
import { BottomUpTransition } from './others/BottomUpTransition'
import Item from './others/Item'
import FindContractUnit from './giardino/FindContractUnit'

export default function CasaraoGiardino() {
    const [isNewContractModalOpen, setNewContractModalOpen] = React.useState<boolean>(false)
    const [isContractManagerModalOpen, setContractManagerModalOpen] = React.useState<boolean>(false)

    const newContractModal = (
        <Dialog fullScreen open={isNewContractModalOpen} onClose={() => setNewContractModalOpen(false)} TransitionComponent={BottomUpTransition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setNewContractModalOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Novo contrato
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => setNewContractModalOpen(false)}>
                        fechar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                <NewContractUnit />
            </Container>
        </Dialog>
    )

    const manageContractModal = (
        <Dialog fullScreen open={isContractManagerModalOpen} onClose={() => setContractManagerModalOpen(false)} TransitionComponent={BottomUpTransition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setContractManagerModalOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Gerenciamento de contratos
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => setContractManagerModalOpen(false)}>
                        fechar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                <FindContractUnit />
            </Container>
        </Dialog>
    )

    return (
        <Container sx={{ px: { xs: 0 } }}>
            {isNewContractModalOpen && newContractModal}
            {isContractManagerModalOpen && manageContractModal}
            <Item sx={{ mb: 2 }}>
                <Typography variant="h3" component="div" gutterBottom>
                    Casarão Giardino
                </Typography>
            </Item>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item>
                        <Button variant="contained" color="primary" onClick={() => setNewContractModalOpen(true)}>
                            Novo contrato
                        </Button>
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <Button variant="contained" color="primary" onClick={() => setContractManagerModalOpen(true)}>
                            Gerenciar contrato
                        </Button>
                    </Item>
                </Grid>
            </Grid>
        </Container>
    )
}
