import * as React from 'react'
import { Stack, TextField, Typography } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import { IsNullOrUndefined, IsStringNullOrWhitespace, isValidCPF } from '../../helpers/GeneralUtilities'
import { HTTP } from '../../helpers/HTTP'
import FabDevDialogBox from '../others/FabDevDialogBox'
import { getAccessToken } from '../../auth/AccessTokenHelper'
import SendIcon from '@mui/icons-material/Send'
import { DateTime } from 'luxon'
import { ApiReturn } from '../../interfaces/Interfaces'
import CpfInputField from '../inputs/CpfInputField'
import { LoadingButton } from '@mui/lab'
import Item from '../others/Item'

interface FitAppCustomer {
    fullName: string
    cpf: string
    email: string
    objectives: string
    subscriptionActive: boolean
    joinDate: DateTime
    hash: string
}

const newCustomer: FitAppCustomer = {
    fullName: '',
    cpf: '',
    email: '',
    objectives: '',
    subscriptionActive: false,
    joinDate: DateTime.utc(),
    hash: ''
}

export default function FitApp() {
    const { instance, accounts, inProgress } = useMsal()
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [successfullySaveCustomer, setSuccessfullySaveCustomer] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [currentCustomer, setCurrentCustomer] = React.useState<FitAppCustomer>(newCustomer)
    const [fieldsWithErrors, setFieldsWithErrors] = React.useState<string[]>([])

    const saveCustomer = async () => {
        if (!isCustomerValid()) {
            setErrorMessage(`Cliente inválido`)
            return;
        }
        const url = `api/abraaofitapp/customer`
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const apiReturn = await HTTP.PostData<ApiReturn>(url, currentCustomer, authResult?.accessToken)
            if (!IsNullOrUndefined(apiReturn) && !IsStringNullOrWhitespace(apiReturn?.message)) {
                setErrorMessage(apiReturn.message)
            }
            if (apiReturn?.success) {
                setSuccessfullySaveCustomer(true);
            }
        } catch (ex: any) {
            setErrorMessage(`${url} ${ex.message}`)
        } finally {
            setIsLoading(false)
        }
    }

    const isCustomerValid = () => {
        if (IsNullOrUndefined(currentCustomer)) {
            addFieldError('fullName')
            addFieldError('cpf')
            addFieldError('email')
            addFieldError('objectives')
            return false;
        }
        var validCustomer = true;
        if (IsStringNullOrWhitespace(currentCustomer.fullName)) {
            addFieldError('fullName')
            validCustomer = false;
        }
        if (IsStringNullOrWhitespace(currentCustomer.cpf)) {
            addFieldError('cpf')
            validCustomer = false;
        }
        if (IsStringNullOrWhitespace(currentCustomer.email)) {
            addFieldError('email')
            validCustomer = false;
        }
        if (IsStringNullOrWhitespace(currentCustomer.objectives)) {
            addFieldError('objectives')
            validCustomer = false;
        }
        return validCustomer;
    }

    const addFieldError = (fieldName: string) => {
        if (fieldsWithErrors.includes(fieldName)) {
            return
        } else {
            setFieldsWithErrors([...fieldsWithErrors, fieldName])
        }
    }

    const setFullName = (fullName: string) => {
        if (IsStringNullOrWhitespace(fullName)) {
            setErrorMessage('Nome completo deve ser informado')
            addFieldError('fullName')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'fullName'))
            setCurrentCustomer({ ...currentCustomer, fullName })
        }
    }

    const setCpfValue = (cpf: string, validate: boolean) => {
        if (!validate) {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'cpf'))
            setCurrentCustomer({ ...currentCustomer, cpf })
            return
        }
        if (!isValidCPF(cpf)) {
            setErrorMessage('CPF inválido')
            addFieldError('cpf')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'cpf'))
            setCurrentCustomer({ ...currentCustomer, cpf })
        }
    }

    const setEmail = (email: string) => {
        if (IsStringNullOrWhitespace(email)) {
            setErrorMessage('Email deve ser informado')
            addFieldError('email')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'email'))
            setCurrentCustomer({ ...currentCustomer, email })
        }
    }

    const setObjectives = (objectives: string) => {
        if (IsStringNullOrWhitespace(objectives)) {
            setErrorMessage('Objetivos deve ser informado')
            addFieldError('objectives')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'objectives'))
            setCurrentCustomer({ ...currentCustomer, objectives })
        }
    }

    return (
        <>
            {!IsStringNullOrWhitespace(errorMessage) && <FabDevDialogBox title="Erro durante requisição" message={errorMessage} />}
            <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Item>
                    <Stack>
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Nome completo"
                            value={currentCustomer.fullName}
                            onBlur={(elem: any) => setFullName(elem.target.value)}
                            onChange={(elem: any) => setFullName(elem.target.value)}
                            error={fieldsWithErrors.includes('fullName')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="CPF"
                            InputProps={{ inputComponent: CpfInputField as any }}
                            value={currentCustomer.cpf}
                            onBlur={(elem: any) => setCpfValue(elem.target.value, true)}
                            onChange={(elem: any) => setCpfValue(elem.target.value, false)}
                            error={fieldsWithErrors.includes('cpf')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Email"
                            value={currentCustomer.email}
                            onBlur={(elem: any) => setEmail(elem.target.value)}
                            onChange={(elem: any) => setEmail(elem.target.value)}
                            error={fieldsWithErrors.includes('email')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Objetivos"
                            value={currentCustomer.objectives}
                            onBlur={(elem: any) => setObjectives(elem.target.value)}
                            onChange={(elem: any) => setObjectives(elem.target.value)}
                            error={fieldsWithErrors.includes('objectives')}
                        />
                    </Stack>
                </Item>
                {successfullySaveCustomer && <Typography>{`Cliente ${currentCustomer.fullName} salvo com sucesso`}</Typography>}
                <Item>
                    <LoadingButton
                        onClick={elem => saveCustomer()}
                        endIcon={<SendIcon />}
                        loading={isLoading}
                        disabled={isLoading}
                        loadingPosition="end"
                        color="success"
                        variant="contained">
                        Salvar cliente
                    </LoadingButton>
                </Item>
            </Stack>
        </>
    )
}
