import { Configuration } from "@azure/msal-browser";

export const msalConfig : Configuration = {
    auth: {
        clientId: '649da049-67d0-4e3e-b8fb-daa97fee97bf',
        authority: 'https://login.microsoftonline.com/357126b7-d8de-41e3-be13-78d0458e34be', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,  
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored -> localStorage or sessionStorage
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
}

// Add scopes here for ID token to be used at a single API
//If you add scopes for more than one API, you'll get an error
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
export const loginRequest = {
    scopes: ['api://FabDev.Api.8ed60daa-7d1e-45a0-ba8a-794967b7cbf1/AccessAPI'],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
