import * as React from 'react'
import { base64ToArrayBuffer, getDateTime, IsNullOrUndefined, IsStringNullOrWhitespace, isValidCPF, replaceAndCastIfString } from '../../helpers/GeneralUtilities'
import { AppBar, Button, Container, Dialog, IconButton, InputAdornment, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextFieldProps, Toolbar, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import CpfInputField from '../inputs/CpfInputField'
import CepInputField from '../inputs/CepInputField'
import MoneyInputField from '../inputs/MoneyInputField'
import { GetGiardinoInvalidFields } from '../../helpers/GeneralUtilities'
import Item from '../others/Item'
import PdfViewerUnit from '../PdfViewerUnit'
import SendIcon from '@mui/icons-material/Send'
import { BottomUpTransition } from '../others/BottomUpTransition'
import CloseIcon from '@mui/icons-material/Close'
import { ContractFields, blankContract, InstallmentPayment, blankInstallment } from '../../interfaces/Interfaces'
import { DateTime } from 'luxon'
import { useMsal } from '@azure/msal-react'
import FabDevDialogBox from '../others/FabDevDialogBox'
import { CepRegex, MoneyRegex, RgRegex } from '../../helpers/Regex'
import { HTTP } from '../../helpers/HTTP'
import { getAccessToken } from '../../auth/AccessTokenHelper'

interface ContractInputProps {
    ContractData?: ContractFields
    EditingMode?: boolean
    onEditSave?: (contract: ContractFields) => void
}

export default function ContratoInputField({ ContractData, EditingMode, onEditSave }: ContractInputProps) {
    const { instance, accounts, inProgress } = useMsal()
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isEditing, setIsEditing] = React.useState<boolean>(EditingMode ?? false)
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false)
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState<boolean>(false)
    const [isInstallmentsDialogOpen, setIsInstallmentsDialogOpen] = React.useState<boolean>(false)
    const [canRenderPdf, setCanRenderPdf] = React.useState<boolean>(false)
    const [contractData, setContractData] = React.useState<ContractFields>(blankContract)
    const [pdfBytes, setPdfBytes] = React.useState<Uint8Array>(new Uint8Array())
    const [fieldsWithErrors, setFieldsWithErrors] = React.useState<string[]>([])
    const [addingInstallment, setAddingInstallment] = React.useState<InstallmentPayment>(blankInstallment)
    const [installments, setInstallments] = React.useState<InstallmentPayment[]>([])

    React.useEffect(() => {
        fillContractData(ContractData ?? blankContract)
    }, [])

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    const toggleSnackbar = () => {
        setIsSnackbarOpen(!isSnackbarOpen)
    }

    const toggleLoading = (isLoading: boolean) => {
        setIsLoading(isLoading)
    }

    const addFieldError = (fieldName: string) => {
        if (fieldsWithErrors.includes(fieldName)) {
            return
        } else {
            setFieldsWithErrors([...fieldsWithErrors, fieldName])
        }
    }

    const fillContractData = (contractData: ContractFields) => {
        const convertedData: ContractFields = {
            ...contractData,
            contractDate: getDateTime(contractData.contractDate),
            startDate: getDateTime(contractData.startDate),
            endDate: getDateTime(contractData.endDate),
            installments: contractData.installments.map(inst => {
                return { ...inst, dueDate: getDateTime(inst.dueDate), paidDate: getDateTime(inst.paidDate) }
            }),
        }
        setCanRenderPdf(false)
        setContractData(convertedData)
    }

    const renderPdf = async () => {
        try {
            if (IsNullOrUndefined(contractData)) {
                setErrorMessage('Erro desconhecido. Verifique os campos e tente novamente')
                addFieldError('CONTRACTINPUT_RENDERPDF')
                return
            }
            const invalidFields = GetGiardinoInvalidFields(contractData)
            if (invalidFields.length > 0 || (fieldsWithErrors.length ?? 0) > 0) {
                setErrorMessage('Alguns campos possuem erros, verifique e tente novamente')
            }
            setIsLoading(true)
            if (!IsNullOrUndefined(contractData.advancePayment) && contractData.advancePayment === '') {
                contractData.advancePayment = '0'
            }
            var blankInstallments: InstallmentPayment[] = []
            var giardinoState = {
                ...contractData,
                rentValue: Number(contractData.rentValue.replace(',', '.')),
                powerRate: Number(contractData.powerRate.replace(',', '.')),
                advancePayment: Number(contractData.advancePayment?.replace(',', '.') ?? '0'),
                installments: blankInstallments,
            }
            if (contractData.installments.length > 0) {
                var numberInstallments = contractData.installments.map((i, index) => {
                    return { ...i, installment: index + 1, value: replaceAndCastIfString(i.value, ',', '.') }
                })
                giardinoState.installments = numberInstallments
            }
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const pdfResponse = await HTTP.PostData<{ success: boolean; pdfFile: string }>(`api/giardino/contract${isEditing ? '/edit' : ''}`, giardinoState, authResult?.accessToken)
            if (IsNullOrUndefined(pdfResponse)) {
                setErrorMessage('Não foi possível salvar o PDF. Tente novamente.')
                addFieldError('CONTRACTINPUT_RENDERPDF')
            } else {
                var array = base64ToArrayBuffer(pdfResponse.pdfFile)
                setPdfBytes(array)
                setCanRenderPdf(true)
            }
        } catch (ex: any) {
            setErrorMessage(ex.message)
            addFieldError('CONTRACTINPUT_RENDERPDF')
        } finally {
            setIsLoading(false)
        }
    }

    const toggleInstallmentsDialog = (action: 'OPEN' | 'CLOSE') => {
        if (IsNullOrUndefined(contractData)) {
            setErrorMessage('Erro desconhecido. Verifique os campos e tente novamente')
            addFieldError('CONTRACTINPUT_RENDERPDF')
            return
        }
        if (isInstallmentsDialogOpen === true && action === 'OPEN') {
            return
        }
        setIsInstallmentsDialogOpen(action === 'OPEN')
    }

    const setRgValue = (rg: string, validate: boolean) => {
        if (!validate) {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'rg'))
            setContractData({ ...contractData, rg })
            return
        }
        if (!IsNullOrUndefined(rg) && !RgRegex.test(rg)) {
            setErrorMessage('RG inválido')
            addFieldError('rg')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'rg'))
            setContractData({ ...contractData, rg })
        }
    }

    const setCpfValue = (cpf: string, validate: boolean) => {
        if (!validate) {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'cpf'))
            setContractData({ ...contractData, cpf })
            return
        }
        if (!isValidCPF(cpf)) {
            setErrorMessage('CPF inválido')
            addFieldError('cpf')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'cpf'))
            setContractData({ ...contractData, cpf })
        }
    }

    const setCepValue = (cep: string, validate: boolean) => {
        if (!validate) {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'cep'))
            setContractData({ ...contractData, cep })
            return
        }
        if (!IsNullOrUndefined(cep) && !CepRegex.test(cep)) {
            setErrorMessage('CEP inválido')
            addFieldError('cep')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'cep'))
            setContractData({ ...contractData, cep })
        }
    }

    const setAdultsValue = (adults: string, validate: boolean) => {
        var adultsNum = Number(adults)
        if (!validate) {
            if (isNaN(adultsNum) && !IsNullOrUndefined(contractData) && contractData.adults > 0) {
                adultsNum = contractData.adults
            }
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'adults'))
            setContractData({ ...contractData, adults: isNaN(adultsNum) ? 0 : adultsNum })
            return
        }
        if (!/\d+/.test(adults) || isNaN(adultsNum) || adultsNum <= 0) {
            setErrorMessage('Número de adultos inválido')
            addFieldError('adults')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'adults'))
            setContractData({ ...contractData, adults: isNaN(adultsNum) ? 0 : adultsNum })
        }
    }

    const setChildrenValue = (children: string, validate: boolean) => {
        var childrenNum = Number(children)
        if (!validate) {
            if (isNaN(childrenNum) && !IsNullOrUndefined(contractData) && contractData.children > 0) {
                childrenNum = contractData.children
            }
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'children'))
            setContractData({ ...contractData, children: isNaN(childrenNum) ? 0 : childrenNum })
            return
        }
        if (!/\d+/.test(children) || isNaN(childrenNum) || childrenNum < 0) {
            setErrorMessage('Número de crianças inválido')
            addFieldError('children')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'children'))
            setContractData({ ...contractData, children: isNaN(childrenNum) ? 0 : childrenNum })
        }
    }

    const setRentValue = (rentValue: string, validate: boolean) => {
        if (!validate) {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'rentValue'))
            setContractData({ ...contractData, rentValue })
            return
        }
        if (!IsNullOrUndefined(rentValue) && !MoneyRegex.test(rentValue)) {
            setErrorMessage('Valor do aluguel inválido')
            addFieldError('rentValue')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'rentValue'))
            setContractData({ ...contractData, rentValue })
        }
    }

    const setPowerRateValue = (powerRate: string, validate: boolean) => {
        if (!validate) {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'powerRate'))
            setContractData({ ...contractData, powerRate })
            return
        }
        if (!MoneyRegex.test(powerRate)) {
            setErrorMessage('Valor da taxa de energia inválido')
            addFieldError('powerRate')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'powerRate'))
            setContractData({ ...contractData, powerRate })
        }
    }

    const setAdvancePaymentValue = (validate: boolean, advancePayment?: string) => {
        if (!validate) {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'advancedPayment'))
            setContractData({ ...contractData, advancePayment: advancePayment ?? '' })
            return
        }
        if (IsNullOrUndefined(advancePayment) || advancePayment === '') {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'advancedPayment'))
            setContractData({ ...contractData, advancePayment: advancePayment ?? '' })
        } else {
            if (!MoneyRegex.test(advancePayment)) {
                setErrorMessage('Valor do pagamento adiantado inválido')
                addFieldError('advancedPayment')
            } else {
                setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'advancedPayment'))
                setContractData({ ...contractData, advancePayment: advancePayment ?? '' })
            }
        }
    }

    const setFullName = (fullName: string) => {
        if (IsStringNullOrWhitespace(fullName)) {
            setErrorMessage('Nome completo deve ser informado')
            addFieldError('fullName')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'fullName'))
            setContractData({ ...contractData, fullName })
        }
    }

    const setCity = (city: string) => {
        if (IsStringNullOrWhitespace(city)) {
            setErrorMessage('Cidade deve ser informada')
            addFieldError('city')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'city'))
            setContractData({ ...contractData, city })
        }
    }

    const setState = (state: string) => {
        if (IsStringNullOrWhitespace(state)) {
            setErrorMessage('Estado deve ser informado')
            addFieldError('state')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'state'))
            setContractData({ ...contractData, state })
        }
    }

    const setAddress = (address: string) => {
        if (IsStringNullOrWhitespace(address)) {
            setErrorMessage('Endreço deve ser informado')
            addFieldError('address')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'address'))
            setContractData({ ...contractData, address })
        }
    }

    const setNationality = (nationality: string) => {
        if (IsStringNullOrWhitespace(nationality)) {
            setErrorMessage('Nacionalidade deve ser informada')
            addFieldError('nationality')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'nationality'))
            setContractData({ ...contractData, nationality })
        }
    }

    const setMaritalStatus = (maritalStatus: 'Solteiro (a)' | 'Casado (a)' | '') => {
        if (IsStringNullOrWhitespace(maritalStatus)) {
            setErrorMessage('Estado civil deve ser informado')
            addFieldError('maritalStatus')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'maritalStatus'))
            setContractData({ ...contractData, maritalStatus })
        }
    }

    const setStartDateValue = (startDate?: DateTime) => {
        if (IsNullOrUndefined(startDate) || (!IsNullOrUndefined(contractData.endDate) && contractData.endDate < startDate)) {
            setErrorMessage('Data de entrada deve ser informada e deve ser menor que a data de saída')
            addFieldError('startDate')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'startDate'))
            setContractData({ ...contractData, startDate })
        }
    }

    const setEndDateValue = (endDate?: DateTime) => {
        if (IsNullOrUndefined(endDate) || (!IsNullOrUndefined(contractData.startDate) && endDate < contractData.startDate)) {
            setErrorMessage('Data de saida deve ser informada e deve ser maior que a data de entrada')
            addFieldError('endDate')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'endDate'))
            setContractData({ ...contractData, endDate })
        }
    }

    const setContractDateValue = (contractDate?: DateTime) => {
        if (IsNullOrUndefined(contractDate)) {
            setErrorMessage('Data do contrato deve ser informada')
            addFieldError('contractDate')
        } else {
            setFieldsWithErrors(fieldsWithErrors.filter(v => v !== 'contractDate'))
            setContractData({ ...contractData, contractDate })
        }
    }

    const setAddingInstallmentValue = (inputValue: string | number, validate: boolean) => {
        if (typeof inputValue === 'number') {
            inputValue = inputValue.toString()
        }
        if (!validate) {
            setAddingInstallment({ ...addingInstallment, value: inputValue })
            return
        }
        if (!IsNullOrUndefined(inputValue) && !MoneyRegex.test(inputValue)) {
            setErrorMessage('Valor da parcela inválido')
            addFieldError('addingInstallmentValue')
        } else {
            setAddingInstallment({ ...addingInstallment, value: inputValue })
        }
    }

    const setAddingInstallmentDueDate = (installmentDueDate?: DateTime) => {
        if (IsNullOrUndefined(installmentDueDate)) {
            setErrorMessage('Data da parcela deve ser informada')
            addFieldError('addingInstalmentDueDate')
        } else {
            setAddingInstallment({ ...addingInstallment, dueDate: installmentDueDate })
        }
    }

    const addAddingInstallment = () => {
        if (IsNullOrUndefined(addingInstallment) || IsStringNullOrWhitespace(addingInstallment.value)) {
            setErrorMessage('Erro adicionando parcela. Tente novamente!')
            addFieldError('addingInstalment')
        } else {
            setInstallments([...installments, addingInstallment])
            setAddingInstallment(blankInstallment)
        }
    }

    const setInstallmentValue = (value: string | number, index: number, validate: boolean) => {
        const currentInstallment = installments[index]
        if (IsNullOrUndefined(currentInstallment)) {
            setErrorMessage('Parcela não encontrada')
            addFieldError(`installmentValue${index}`)
            return
        }
        const installment: InstallmentPayment = { ...currentInstallment, value }
        if (!validate) {
            var currentInstallments = installments
            currentInstallments[index] = installment
            setInstallments([...currentInstallments])
            return
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        if (!MoneyRegex.test(value)) {
            setErrorMessage('Valor da parcela inválido')
            addFieldError(`installmentValue${index}`)
        } else {
            var currentInstallments = installments
            currentInstallments[index] = installment
            setInstallments([...currentInstallments])
        }
    }

    const setInstallmentDueDate = (index: number, date?: DateTime) => {
        const currentInstallment = installments[index]
        if (IsNullOrUndefined(currentInstallment)) {
            setErrorMessage('Parcela não encontrada')
            addFieldError(`installmentDueDate${index}`)
            return
        }
        if (IsNullOrUndefined(date)) {
            setErrorMessage('Data de vencimento inválida')
            addFieldError(`installmentDueDate${index}`)
            return
        }
        const installment: InstallmentPayment = { ...currentInstallment, dueDate: date }
        var currentInstallments = installments
        currentInstallments[index] = installment
        setInstallments([...currentInstallments])
    }

    const setInstallmentPaidDate = (index: number, date?: DateTime) => {
        const currentInstallment = installments[index]
        if (IsNullOrUndefined(currentInstallment)) {
            setErrorMessage('Parcela não encontrada')
            addFieldError(`installmentDueDate${index}`)
            return
        }
        if (IsNullOrUndefined(date)) {
            setErrorMessage('Data de pagamento inválida')
            addFieldError(`installmentDueDate${index}`)
            return
        }
        const installment: InstallmentPayment = { ...currentInstallment, paidDate: date }
        var currentInstallments = installments
        currentInstallments[index] = installment
        setInstallments([...currentInstallments])
    }

    const removeInstallmentIndex = (index: number) => {
        const currentInstallment = installments[index]
        if (IsNullOrUndefined(currentInstallment)) {
            setErrorMessage('Parcela não encontrada')
            addFieldError(`installmentDueDate${index}`)
            return
        }
        var currentInstallments = installments
        currentInstallments.splice(index, 1)
        setInstallments([...currentInstallments])
    }

    function closeInstallmentsDialog() {
        setIsInstallmentsDialogOpen(!isInstallmentsDialogOpen)
    }

    var installmentsDialog = <></>
    if (!isLoading && isInstallmentsDialogOpen) {
        installmentsDialog = (
            <Dialog fullScreen open={isInstallmentsDialogOpen} onClose={closeInstallmentsDialog} TransitionComponent={BottomUpTransition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={closeInstallmentsDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {IsStringNullOrWhitespace(contractData?.fullName) ? 'Parcelas do novo contrato' : `Parcelas do contrato de ${contractData?.fullName}`}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={closeInstallmentsDialog}>
                            fechar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        <Item>
                            <TextField
                                sx={{ m: 1 }}
                                variant="filled"
                                label="Valor da parcela"
                                InputProps={{
                                    inputComponent: MoneyInputField as any,
                                    onChange: (elem: any) => setAddingInstallmentValue(elem.target.value, false),
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                }}
                                value={addingInstallment.value}
                                onBlur={elem => setAddingInstallmentValue(addingInstallment.value, true)}
                                error={fieldsWithErrors.includes('addingInstallmentValue')}
                            />
                        </Item>
                        <Item>
                            <DatePicker
                                renderInput={(props: TextFieldProps) => <TextField sx={{ m: 1 }} variant="filled" {...props} />}
                                label="Data do vencimento"
                                value={addingInstallment.dueDate}
                                mask="__/__/____"
                                onChange={(newValue: DateTime | null, keyboardInputValue?: string | undefined) => setAddingInstallmentDueDate(newValue ?? undefined)}
                            />
                        </Item>
                        <Item>
                            <Button variant="contained" color="primary" onClick={elem => addAddingInstallment()}>
                                Adicionar
                            </Button>
                        </Item>
                        <Item>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Parcela</TableCell>
                                            <TableCell align="center">Valor</TableCell>
                                            <TableCell align="center">Data vencimento</TableCell>
                                            <TableCell align="center">Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {contractData.installments.map((installment, i) => (
                                            <TableRow key={`installment${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        sx={{ m: 1 }}
                                                        variant="filled"
                                                        label="Valor da parcela"
                                                        InputProps={{
                                                            inputComponent: MoneyInputField as any,
                                                            onChange: (elem: any) => setInstallmentValue(elem.target.value, i, false),
                                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                        }}
                                                        value={installment.value.toString()}
                                                        onBlur={(elem: any) => setInstallmentValue(contractData.installments[i].value, i, true)}
                                                        error={fieldsWithErrors.includes(`installmentValue${i}`)}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <DatePicker
                                                        renderInput={(props: TextFieldProps) => <TextField sx={{ m: 1 }} variant="filled" {...props} />}
                                                        label="Data do vencimento"
                                                        InputProps={{ error: fieldsWithErrors.includes(`installmentDueDate${i}`) }}
                                                        value={installment.dueDate}
                                                        mask="__/__/____"
                                                        onChange={(newValue: DateTime | null, keyboardInputValue?: string | undefined) => setInstallmentDueDate(i, newValue ?? undefined)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button variant="contained" color="warning" onClick={elem => removeInstallmentIndex(i)}>
                                                        Remover
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Item>
                    </Stack>
                </Container>
            </Dialog>
        )
    }
    return (
        <>
            {!IsStringNullOrWhitespace(errorMessage) && <FabDevDialogBox title="Erro durante requisição" message={errorMessage} />}
            {installmentsDialog}
            <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Item>
                    <Stack>
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Nome completo"
                            value={contractData.fullName}
                            onBlur={(elem: any) => setFullName(elem.target.value)}
                            onChange={(elem: any) => setFullName(elem.target.value)}
                            error={fieldsWithErrors.includes('fullName')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Estado Civil"
                            select
                            value={contractData.maritalStatus}
                            onChange={(elem: any) => setMaritalStatus(elem.target.value)}
                            error={fieldsWithErrors.includes('maritalStatus')}>
                            <MenuItem value={''}>Selecione</MenuItem>
                            <MenuItem value={'Solteiro (a)'}>Solteiro (a)</MenuItem>
                            <MenuItem value={'Casado (a)'}>Casado (a)</MenuItem>
                        </TextField>
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Nacionalidade"
                            value={contractData.nationality}
                            onBlur={(elem: any) => setNationality(elem.target.value)}
                            onChange={(elem: any) => setNationality(elem.target.value)}
                            error={fieldsWithErrors.includes('nationality')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="RG"
                            value={contractData.rg}
                            onBlur={(elem: any) => setRgValue(elem.target.value, true)}
                            onChange={(elem: any) => setRgValue(elem.target.value, false)}
                            error={fieldsWithErrors.includes('rg')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="CPF"
                            InputProps={{ inputComponent: CpfInputField as any }}
                            value={contractData.cpf}
                            onBlur={(elem: any) => setCpfValue(elem.target.value, true)}
                            onChange={(elem: any) => setCpfValue(elem.target.value, false)}
                            error={fieldsWithErrors.includes('cpf')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Endereço completo"
                            value={contractData.address}
                            onBlur={(elem: any) => setAddress(elem.target.value)}
                            onChange={(elem: any) => setAddress(elem.target.value)}
                            error={fieldsWithErrors.includes('address')}
                        />
                        <TextField sx={{ m: 1 }} variant="filled" label="Cidade" value={contractData.city} onChange={(elem: any) => setCity(elem.target.value)} error={fieldsWithErrors.includes('city')} />
                        <TextField sx={{ m: 1 }} variant="filled" label="Estado" value={contractData.state} onChange={(elem: any) => setState(elem.target.value)} error={fieldsWithErrors.includes('state')} />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="CEP"
                            InputProps={{ inputComponent: CepInputField as any }}
                            value={contractData.cep}
                            onBlur={(elem: any) => setCepValue(elem.target.value, true)}
                            onChange={(elem: any) => setCepValue(elem.target.value, false)}
                            error={fieldsWithErrors.includes('cep')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Adultos"
                            value={contractData.adults}
                            onBlur={(elem: any) => setAdultsValue(elem.target.value, true)}
                            onChange={(elem: any) => setAdultsValue(elem.target.value, false)}
                            error={fieldsWithErrors.includes('adults')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Crianças"
                            value={contractData.children}
                            onBlur={(elem: any) => setChildrenValue(elem.target.value, true)}
                            onChange={(elem: any) => setChildrenValue(elem.target.value, false)}
                            error={fieldsWithErrors.includes('children')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Valor do aluguel"
                            InputProps={{ inputComponent: MoneyInputField as any, onChange: (elem: any) => setRentValue(elem.target.value, false), startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                            value={contractData.rentValue}
                            onBlur={(elem: any) => setRentValue(contractData.rentValue, true)}
                            error={fieldsWithErrors.includes('rentValue')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Taxa de energia"
                            InputProps={{ inputComponent: MoneyInputField as any, onChange: (elem: any) => setPowerRateValue(elem.target.value, false), startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                            value={contractData.powerRate}
                            onBlur={(elem: any) => setPowerRateValue(contractData.powerRate, true)}
                            error={fieldsWithErrors.includes('powerRate')}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            variant="filled"
                            label="Valor da entrada"
                            InputProps={{ inputComponent: MoneyInputField as any, onChange: (elem: any) => setAdvancePaymentValue(false, elem.target.value), startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                            value={contractData.advancePayment}
                            onBlur={elem => setAdvancePaymentValue(true, contractData.advancePayment)}
                            error={fieldsWithErrors.includes('advancePayment')}
                        />
                        <DateTimePicker
                            renderInput={(props: TextFieldProps) => <TextField sx={{ m: 1 }} variant="filled" {...props} />}
                            label="Data/hora de entrada"
                            InputProps={{ error: fieldsWithErrors.includes('startDate') }}
                            mask="__/__/____ __:__"
                            value={contractData.startDate}
                            onChange={(newValue: DateTime | null, keyboardInputValue?: string | undefined) => setStartDateValue(newValue ?? undefined)}
                        />
                        <DateTimePicker
                            renderInput={(props: TextFieldProps) => <TextField sx={{ m: 1 }} variant="filled" {...props} />}
                            label="Data/hora de saída"
                            InputProps={{ error: fieldsWithErrors.includes('endDate') }}
                            mask="__/__/____ __:__"
                            value={contractData.endDate}
                            onChange={(newValue: DateTime | null, keyboardInputValue?: string | undefined) => setEndDateValue(newValue ?? undefined)}
                        />
                        <DatePicker
                            renderInput={(props: TextFieldProps) => <TextField sx={{ m: 1 }} variant="filled" {...props} />}
                            label="Data do contrato"
                            InputProps={{ error: fieldsWithErrors.includes('contractDate') }}
                            mask="__/__/____"
                            value={contractData.contractDate}
                            onChange={(newValue: DateTime | null, keyboardInputValue?: string | undefined) => setContractDateValue(newValue ?? undefined)}
                        />
                        <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading} onClick={elem => toggleInstallmentsDialog('OPEN')}>
                            {contractData.installments?.length > 0 ? `Editar ${contractData.installments.length}` : 'Adicionar'} parcelas
                        </Button>
                    </Stack>
                </Item>
                <Item>
                    <LoadingButton
                        onClick={elem => {
                            toggleLoading(true)
                            if (EditingMode && onEditSave) {
                                onEditSave(contractData)
                            } else {
                                renderPdf()
                            }
                            toggleLoading(false)
                        }}
                        endIcon={<SendIcon />}
                        loading={isLoading}
                        disabled={canRenderPdf || GetGiardinoInvalidFields(contractData).length > 0}
                        loadingPosition="end"
                        color="success"
                        variant="contained">
                        {EditingMode ? 'Salvar contrato' : 'Gerar novo contrato'}
                    </LoadingButton>
                </Item>
                {canRenderPdf && !isLoading && (
                    <Item>
                        <Button variant="contained" color="success" disabled={!canRenderPdf || isLoading} download={`contrato-${contractData.fullName}.pdf`} href={URL.createObjectURL(new Blob([pdfBytes]))}>
                            Download
                        </Button>
                    </Item>
                )}
                {navigator.share && (
                    <Item>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={!canRenderPdf || isLoading}
                            onClick={async () => await navigator.share({ title: 'Contrato Giardino', text: 'Acesso seu contrato com o Casarão Giardino agora mesmo', files: [new File([pdfBytes], 'contrato.pdf', { type: 'application/pdf' })] })}>
                            Compartilhar
                        </Button>
                    </Item>
                )}
                {canRenderPdf && !isLoading && (
                    <Item sx={{ flexGrow: 1, height: 850 }}>
                        <PdfViewerUnit pdfFile={pdfBytes} />
                    </Item>
                )}
            </Stack>
        </>
    )
}
