import { DateTime } from 'luxon'

export interface IApiError {
    type: string
    title: string
    status: number
    traceId: string
    errors: {}
}

export type ContractFields = {
    fullName: string
    maritalStatus: 'Solteiro (a)' | 'Casado (a)' | ''
    nationality: string
    rg: string
    cpf: string
    address: string
    city: string
    state: string
    cep: string
    adults: number
    children: number
    rentValue: string
    powerRate: string
    advancePayment: string
    startDate: DateTime
    endDate: DateTime
    contractDate: DateTime
    installments: InstallmentPayment[]
}

export interface InstallmentPayment {
    dueDate: DateTime
    paidDate: DateTime
    value: string | number
    installment: string | number
    status: 'Pending' | 'Paid' | 'Late' | 'PaidLate'
}

export const blankInstallment: InstallmentPayment = {
    dueDate: DateTime.local(),
    paidDate: DateTime.local(),
    value: '',
    installment: '',
    status: 'Pending'
}

export const blankContract: ContractFields = {
    fullName: '',
    maritalStatus: '',
    nationality: '',
    rg: '',
    cpf: '',
    address: '',
    city: '',
    state: '',
    cep: '',
    adults: 0,
    children: 0,
    rentValue: '',
    powerRate: '',
    advancePayment: '',
    startDate: DateTime.local(),
    endDate: DateTime.local(),
    contractDate: DateTime.local(),
    installments: []
}

export interface ApiReturn {
    success: boolean
    message: string
}

export interface GiardinoPdfApiReturn {
    success: boolean
    message: string
    pdfFile: string
}

export interface GiardinoDocxApiReturn {
    success: boolean
    message: string
    docxFile: string
    /*docxFile: {
        contentType: string
        enableRangeProcessing: boolean
        entityTag: string
        fileContents: string
        fileDownloadName: string
        lastModified: DateTime
    }*/
}

export interface GiardinoContractApiReturn {
    success: boolean
    message: string
    contract: ContractFields
}

export interface GiardinoContractSearchResults {
    fullName: string
    cpf: string
    startDate: DateTime
    endDate: DateTime
    hash: string
}

export interface GiardinoContractSearchHttpResults {
    fullName: string
    cpf: string
    startDate: string
    endDate: string
    hash: string
}