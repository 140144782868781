import * as React from 'react'
import { Container, Typography, Button, Grid, Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import { HTTP } from '../helpers/HTTP'
import { IsNullOrUndefined } from '../helpers/GeneralUtilities'
import Item from './others/Item'
import { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import DocViewer from "@cyntler/react-doc-viewer"
import { useMsal } from '@azure/msal-react'
import { getAccessToken } from '../auth/AccessTokenHelper'

export default function SwaggerDoc() {
    const { instance, accounts, inProgress } = useMsal()
    const [swaggerJson, setSwaggerJson] = React.useState<File | null | undefined>(null)
    const [outputDocxData, setDocxOutput] = React.useState<Blob | null>(null)
    const [outputTxtData, setTxtOutput] = React.useState<string>('')
    const [statusText, setStatusText] = React.useState<string>('Awaiting input')
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [hasInput, setHasInput] = React.useState<boolean>(false)
    const [hasResult, setHasResult] = React.useState<boolean>(false)
    const [docxUrl, setDocxUrl] = React.useState<string>('')

    const onFileChange = async (jsonFile: File | null | undefined) => {
        try {
            setStatusText('Changing file...')
            if (IsNullOrUndefined(jsonFile)) {
                setStatusText('Null JSON file')
                return
            }
            setSwaggerJson(jsonFile)
            setHasInput(true)
            setStatusText(`File changed to ${jsonFile.name}!`)
        } catch (ex: any) {
            handleAPIError(ex)
        } finally {
            setIsLoading(false)
        }
    }

    const uploadFile = async () => {
        try {
            setStatusText('Validating input file...')
            if (!hasInput || IsNullOrUndefined(swaggerJson)) {
                setStatusText('Null JSON file on upload')
                return
            }
            setIsLoading(true)
            setStatusText('Uploading file...')
            const formData = new FormData()
            formData.append('json', swaggerJson, 'swaggerJson.json')
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const docxResponse = await HTTP.PostFormDataBlob('api/swagger/docx', formData, authResult?.accessToken)
            const textResponse = await HTTP.PostFormData<string>('api/swagger/text', formData, authResult?.accessToken)
            if (IsNullOrUndefined(docxResponse) || IsNullOrUndefined(textResponse)) {
                setStatusText('Unable to upload file')
            } else {
                setDocxOutput(docxResponse)
                var data = new Blob([docxResponse], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
                var swaggerURL = window.URL.createObjectURL(data)
                setDocxUrl(swaggerURL)
                setTxtOutput(textResponse)
                setStatusText('File uploaded. Download available!')
                setHasResult(true)
            }
        } catch (ex: any) {
            handleAPIError(ex)
        } finally {
            setIsLoading(false)
        }
    }

    const download = async (action: 'docx' | 'txt') => {
        try {
            setStatusText('Validing download file')
            if (!hasResult || IsNullOrUndefined(outputDocxData)) {
                setStatusText('Null DOCX file to download')
                return
            }
            setIsLoading(true)
            if (action === 'docx') {
                const link = document.createElement('a')
                link.download = 'SwaggerDocument.docx'
                link.href = docxUrl
                link.click()
            } else {
                var data = new Blob([outputTxtData], { type: 'text/plain' })
                var csvURL = window.URL.createObjectURL(data)
                const link = document.createElement('a')
                link.download = 'SwaggerDocument.txt'
                link.href = csvURL
                link.click()
            }
            setStatusText('File downloaded!')
        } catch (ex: any) {
            handleAPIError(ex)
        } finally {
            setIsLoading(false)
        }
    }

    const handleAPIError = (ex: any) => {
        if (ex.message) {
            setStatusText(ex?.message ?? 'No error message returned.')
        } else if (ex.status && ex.statusText) {
            setStatusText(`Error during HTTP request: ${ex.status}: ${ex.statusText}`)
        } else {
            setStatusText('No error message returned.')
        }
    }

    return (
        <Container sx={{ mb: 2 }}>
            <Item sx={{ mb: 2 }}>
                <Typography variant="h3" component="div" gutterBottom>
                    Swagger JSON to Docx
                </Typography>
            </Item>
            <Stack spacing={2}>
                <Stack spacing={2}>
                    <Item>
                        <Grid>
                            <Button sx={{ m: 1 }} variant="contained" component="label" disabled={isLoading}>
                                Select JSON file
                                <input hidden accept="application/json" type="file" onChange={elem => onFileChange(elem.target?.files?.item(0))} />
                            </Button>
                            <LoadingButton sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading || !hasInput} loading={isLoading} endIcon={<SendIcon />} loadingPosition="end" onClick={() => uploadFile()}>
                                Upload
                            </LoadingButton>
                        </Grid>
                    </Item>
                    <Item>
                        <Grid>{statusText}</Grid>
                    </Item>
                    <Item>
                        <Grid>
                            <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading || !hasResult} onClick={() => download('docx')}>
                                Download DOCX
                            </Button>
                            <Button sx={{ m: 1 }} variant="contained" color="primary" disabled={isLoading || !hasResult} onClick={() => download('txt')}>
                                Download TXT
                            </Button>
                        </Grid>
                    </Item>
                    {/* {hasResult && (<Item><DocViewer pluginRenderers={DocViewerRenderers} documents={[ { uri: docxUrl, fileName: 'swaggerDocument.docx' } ]} /></Item>)} */}
                </Stack>
            </Stack>
        </Container>
    )
}
