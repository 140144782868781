import { AppBar, Button, Card, CardContent, Container, Dialog, IconButton, LinearProgress, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { convertContractApiReturnToProperFields, GetGiardinoInvalidFields, IsNullOrUndefined, replaceAndCastIfString } from '../../helpers/GeneralUtilities'
import { HTTP } from '../../helpers/HTTP'
import { blankContract, ContractFields, GiardinoContractApiReturn, GiardinoPdfApiReturn, InstallmentPayment } from '../../interfaces/Interfaces'
import ContratoInputField from '../inputs/ContratoInputField'
import { BottomUpTransition } from '../others/BottomUpTransition'
import CloseIcon from '@mui/icons-material/Close'
import { getAccessToken } from '../../auth/AccessTokenHelper'
import { useMsal } from '@azure/msal-react'

interface EditContractProps {
    ContractHash: string
    onClose: () => void
}

export default function EditContractUnit({ ContractHash, onClose }: EditContractProps) {
    const { instance, accounts, inProgress } = useMsal()
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [canStartEditing, setCanStartEditing] = React.useState<boolean>(false)
    const [contractData, setContractData] = React.useState<ContractFields>(blankContract)

    const GetContractData = async () => {
        try {
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const apiResponse = await HTTP.GetData<GiardinoContractApiReturn>(`api/giardino/contract/${ContractHash}`, authResult?.accessToken)
            if (IsNullOrUndefined(apiResponse)) {
                setErrorMessage('Não foi possível ler o contrato. Tente novamente.')
            } else if (!apiResponse.success) {
                setErrorMessage(apiResponse.message)
            } else {
                setContractData(convertContractApiReturnToProperFields(apiResponse.contract))
                setCanStartEditing(true)
            }
        } catch (ex: any) {
            setErrorMessage(ex.message)
        } finally {
            setIsLoading(false)
        }
    }

    const saveEditedContract = async (hash: string, contract: ContractFields) => {
        try {
            const invalidFields = GetGiardinoInvalidFields(contract)
            if (invalidFields.length > 0) {
                setErrorMessage('Alguns campos possuem erros, verifique e tente novamente')
            }
            setIsLoading(true)
            if (!IsNullOrUndefined(contract.advancePayment) && contract.advancePayment === '') {
                contract.advancePayment = '0'
            }
            var blankInstallments: InstallmentPayment[] = []
            var giardinoState = {
                ...contract,
                rentValue: Number(contract.rentValue.replace(',', '.')),
                powerRate: Number(contract.powerRate.replace(',', '.')),
                advancePayment: Number(contract.advancePayment?.replace(',', '.') ?? '0'),
                installments: blankInstallments,
            }
            if (contract.installments.length > 0) {
                var numberInstallments = contract.installments.map((i, index) => {
                    return { ...i, installment: index + 1, value: replaceAndCastIfString(i.value, ',', '.') }
                })
                giardinoState.installments = numberInstallments
            }
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const pdfResponse = await HTTP.PutData<GiardinoPdfApiReturn>(`api/giardino/contract/${hash}`, giardinoState, authResult?.accessToken)
            if (IsNullOrUndefined(pdfResponse)) {
                setErrorMessage('Não foi possível ler o contrato. Tente novamente.')
            } else if (!pdfResponse.success) {
                setErrorMessage(pdfResponse.message)
            } else {
                onClose()
            }
        } catch (ex: any) {
            setErrorMessage(ex.message)
        } finally {
            setIsLoading(true)
        }
    }

    React.useEffect(() => {
        GetContractData()
    }, [])

    if (isLoading) {
        return <LinearProgress color="secondary" />
    }

    if (canStartEditing) {
        return (
            <Dialog fullScreen open={true} onClose={onClose} TransitionComponent={BottomUpTransition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Contrato de {contractData.fullName}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={onClose}>
                            fechar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container sx={{ mt: 2 }}>
                    <ContratoInputField ContractData={contractData} EditingMode={true} onEditSave={(contract: ContractFields) => saveEditedContract(ContractHash, contract)} />
                </Container>
            </Dialog>
        )
    }

    return (
        <Dialog fullScreen open={true} onClose={onClose} TransitionComponent={BottomUpTransition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Erro
                    </Typography>
                    <Button autoFocus color="inherit" onClick={onClose}>
                        fechar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container sx={{ mt: 2 }}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Aconteceu um erro desconhecido. Tente novamente.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </Dialog>
    )
}
