import { DateTime } from 'luxon'
import { ContractFields } from '../interfaces/Interfaces'
import { CepRegex, MoneyRegex, RgRegex } from './Regex'

function isObjectEmpty(obj: object): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false
        }
    }
    return true
}
export function IsDate(value: any): value is Date {
    return value instanceof Date
}

export function IsStringNullOrWhitespace(value: any): value is null | undefined {
    if (typeof value === 'string') {
        return IsNullOrUndefined(value) || value.length === 0 || !value.trim()
    } else {
        return IsNullOrUndefined(value)
    }
}

export function IsNullOrUndefined(value: any): value is null | undefined {
    return value === null || value === undefined
}

export function isNullOrEmpty(value: unknown): value is null | undefined | '' {
    if (value === null || value === undefined) {
        return true
    }

    switch (typeof value) {
        case 'undefined':
            return true
        case 'string':
            return value === ''
        case 'number':
            return isNaN(value)
        case 'object':
            if (Array.isArray(value)) {
                return value.length < 1
            }

            if (typeof NodeList !== 'undefined' && value instanceof NodeList) {
                return value.length < 1
            }

            if (typeof HTMLCollection !== 'undefined' && value instanceof HTMLCollection) {
                return value.length < 1
            }

            return value?.constructor?.name === 'Object' && isObjectEmpty(value)
        case 'bigint':
        case 'function':
        case 'boolean':
        case 'symbol':
            return false
        default:
            return false
    }
}

export function IsString(value: any): value is string {
    return typeof value === 'string'
}

export function isValidCPF(cpf: string): boolean {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[\s.-]*/gim, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
    ) {
        return false
    }
    var sum = 0
    var remainder
    for (var i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    remainder = (sum * 10) % 11
    if (remainder == 10 || remainder == 11) remainder = 0
    if (remainder != parseInt(cpf.substring(9, 10))) return false
    sum = 0
    for (var j = 1; j <= 10; j++) sum = sum + parseInt(cpf.substring(j - 1, j)) * (12 - j)
    remainder = (sum * 10) % 11
    if (remainder == 10 || remainder == 11) remainder = 0
    if (remainder != parseInt(cpf.substring(10, 11))) return false
    return true
}

export function IsGiardinoContractValid(contract: ContractFields): boolean {
    return (
        !IsStringNullOrWhitespace(contract.address) &&
        contract.adults > 0 &&
        !IsStringNullOrWhitespace(contract.advancePayment) &&
        Number(contract.advancePayment) >= 0 &&
        !IsStringNullOrWhitespace(contract.cep) &&
        contract.children >= 0 &&
        !IsStringNullOrWhitespace(contract.city) &&
        !IsNullOrUndefined(contract.contractDate) &&
        DateTime.isDateTime(contract.contractDate) &&
        !IsStringNullOrWhitespace(contract.cpf) &&
        isValidCPF(contract.cpf) &&
        !IsNullOrUndefined(contract.endDate) &&
        DateTime.isDateTime(contract.endDate) &&
        !IsStringNullOrWhitespace(contract.fullName) &&
        !IsStringNullOrWhitespace(contract.maritalStatus) &&
        !IsStringNullOrWhitespace(contract.nationality) &&
        !IsStringNullOrWhitespace(contract.powerRate) &&
        Number(contract.powerRate) > 0 &&
        !IsStringNullOrWhitespace(contract.rentValue) &&
        Number(contract.rentValue) > 0 &&
        !IsStringNullOrWhitespace(contract.rg) &&
        /^\d+$/.test(contract.rg) &&
        !IsStringNullOrWhitespace(contract.startDate) &&
        DateTime.isDateTime(contract.startDate) &&
        !IsStringNullOrWhitespace(contract.state)
    )
}

export function GetGiardinoInvalidFields(contract: ContractFields): string[] {
    const invalidFields: string[] = []
    if (IsStringNullOrWhitespace(contract.address)) {
        invalidFields.push('address')
    }
    if (IsNullOrUndefined(contract.adults) || contract.adults <= 0) {
        invalidFields.push('adults')
    }
    if (IsNullOrUndefined(contract.adults) || contract.children < 0) {
        invalidFields.push('children')
    }
    if (IsNullOrUndefined(contract.advancePayment) || (contract.advancePayment !== '' && !MoneyRegex.test(contract.advancePayment))) {
        invalidFields.push('advancePayment')
    }
    if (IsStringNullOrWhitespace(contract.powerRate) || !MoneyRegex.test(contract.powerRate)) {
        invalidFields.push('powerRate')
    }
    if (IsStringNullOrWhitespace(contract.rentValue) || !MoneyRegex.test(contract.rentValue)) {
        invalidFields.push('rentValue')
    }
    if (IsStringNullOrWhitespace(contract.cep) || !CepRegex.test(contract.cep)) {
        invalidFields.push('cep')
    }
    if (IsStringNullOrWhitespace(contract.city)) {
        invalidFields.push('city')
    }
    if (IsStringNullOrWhitespace(contract.fullName)) {
        invalidFields.push('fullName')
    }
    if (contract.maritalStatus !== 'Solteiro (a)' && contract.maritalStatus !== 'Casado (a)') {
        invalidFields.push('maritalStatus')
    }
    if (IsStringNullOrWhitespace(contract.nationality)) {
        invalidFields.push('nationality')
    }
    if (IsStringNullOrWhitespace(contract.state)) {
        invalidFields.push('state')
    }
    if (IsStringNullOrWhitespace(contract.cpf) || !isValidCPF(contract.cpf)) {
        invalidFields.push('cpf')
    }
    if (IsStringNullOrWhitespace(contract.rg) || !RgRegex.test(contract.rg)) {
        invalidFields.push('rg')
    }
    if (IsNullOrUndefined(contract.contractDate) || !DateTime.isDateTime(contract.contractDate)) {
        invalidFields.push('contractDate')
    }
    if (IsNullOrUndefined(contract.startDate) || !DateTime.isDateTime(contract.startDate)) {
        invalidFields.push('startDate')
    }
    if (IsNullOrUndefined(contract.endDate) || !DateTime.isDateTime(contract.endDate)) {
        invalidFields.push('endDate')
    }
    if (DateTime.isDateTime(contract.startDate) && DateTime.isDateTime(contract.endDate)) {
        const start = contract.startDate
        const end = contract.endDate
        if (start > end) {
            invalidFields.push('startDate')
        }
    }
    return invalidFields
}

export function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64) // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length)
    return bytes.map((byte, i) => binaryString.charCodeAt(i))
}

export function createAndDownloadBlobFile(body: Uint8Array, filename: string, extension = 'pdf') {
    const blob = new Blob([body])
    const fileName = `${filename}.${extension}`
    const link = document.createElement('a')
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}

export function replaceAndCastIfString(value: string | number, find: string | RegExp, replace: string): number {
    if (typeof value === 'string') {
        return Number(value.replace(find, replace))
    } else {
        return value
    }
}

export function replaceAndCastIfNumber(value: string | number, find: string | RegExp, replace: string): string {
    if (typeof value === 'number') {
        return value.toString().replace(find, replace)
    } else {
        return value.replace(find, replace)
    }
}

export function getDateTime(value: string | DateTime): DateTime {
    if (typeof value === 'string') {
        return DateTime.fromISO(value)
    } else {
        return value
    }
}

export function convertContractApiReturnToProperFields(contract: ContractFields): ContractFields {
    return {
        ...contract,
        contractDate: getDateTime(contract.contractDate),
        startDate: getDateTime(contract.startDate),
        endDate: getDateTime(contract.endDate),
        rentValue: replaceAndCastIfNumber(contract.rentValue, '.', ','),
        powerRate: replaceAndCastIfNumber(contract.powerRate, '.', ','),
        advancePayment: replaceAndCastIfNumber(contract.advancePayment ?? '0', '.', ','),
        installments: contract.installments.map(inst => { return { ...inst, dueDate: getDateTime(inst.dueDate), paidDate: getDateTime(inst.paidDate) } })
    }
}