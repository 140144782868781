import * as React from 'react'
import { IsNullOrUndefined, IsStringNullOrWhitespace } from '../../helpers/GeneralUtilities'
import { Stack, TextField, TextFieldProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Menu, MenuList, MenuItem, Paper } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers'
import Item from '../others/Item'
import SendIcon from '@mui/icons-material/Send'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { GiardinoContractSearchResults, GiardinoContractSearchHttpResults } from '../../interfaces/Interfaces'
import { DateTime } from 'luxon'
import { HTTP } from '../../helpers/HTTP'
import { useMsal } from '@azure/msal-react'
import { getAccessToken } from '../../auth/AccessTokenHelper'
import PdfViewerDialog from './PdfViewerDialog'
import DocxViewerDialog from './DocxViewerDialog'
import EditContractUnit from './EditContractUnit'
import FabDevDialogBox from '../others/FabDevDialogBox'
import ReceiptUnit from './ReceiptUnit'

export default function FindContractUnit() {
    const { instance, accounts, inProgress } = useMsal()
    const [pdfViewerDialogOpen, setPdfViewerDialogOpen] = React.useState<boolean>(false)
    const [docxViewerDialogOpen, setDocxViewerDialogOpen] = React.useState<boolean>(false)
    const [editContractDialogOpen, setEditContractDialogOpen] = React.useState<boolean>(false)
    const [receiptDialogOpen, setReceiptDialogOpen] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [contractHash, setContractHash] = React.useState<string>('')
    const [contractName, setContractName] = React.useState<string>('')
    const [anchorElemsContractActionsMenu, setAnchorElContractActionsMenu] = React.useState<(null | HTMLElement)[]>([null])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [hasFirstSearch, setFirstSearch] = React.useState<boolean>(false)
    const [searchParams, setSearchParams] = React.useState<{ search?: string; start?: DateTime; end?: DateTime }>({ search: '', start: DateTime.local().minus({ month: 1 }), end: DateTime.local() })
    const [contractResults, setContractResults] = React.useState<GiardinoContractSearchResults[]>([])
    const fromDateElRef = React.useRef()
    const toDateElRef = React.useRef()

    const contractPdfModal = <PdfViewerDialog ContractHash={contractHash} ContractName={contractName} onClose={() => setPdfViewerDialogOpen(false)} />
    const contractDocxModal = <DocxViewerDialog ContractHash={contractHash} ContractName={contractName} onClose={() => setDocxViewerDialogOpen(false)} />
    const editContractModal = <EditContractUnit ContractHash={contractHash} onClose={() => setEditContractDialogOpen(false)} />
    const receiptModal = <ReceiptUnit ContractHash={contractHash} ContractName={contractName} onClose={() => setReceiptDialogOpen(false)} />

    const handleActionsMenuOpen = (rowId: number, event: React.MouseEvent<HTMLElement>) => {
        let elems = [...anchorElemsContractActionsMenu]
        elems[rowId] = event.currentTarget
        setAnchorElContractActionsMenu(elems)
    }

    const handleActionsMenuClose = (rowId: number) => {
        let elems = [...anchorElemsContractActionsMenu]
        elems[rowId] = null
        setAnchorElContractActionsMenu(elems)
    }

    const handleSearchStringChange = (newSearch?: string) => {
        setSearchParams({ ...searchParams, search: newSearch })
    }

    const handleSearchStartChange = (newStart?: DateTime) => {
        setSearchParams({ ...searchParams, start: newStart })
    }

    const handleSearchEndChange = (newEnd?: DateTime) => {
        setSearchParams({ ...searchParams, end: newEnd })
    }

    const searchContracts = async () => {
        try {
            const search = searchParams.search ?? ''
            const start = searchParams.start
            const end = searchParams.end
            setIsLoading(true)
            var queryString = new URLSearchParams()
            if (!IsNullOrUndefined(start)) {
                queryString.append('start', start.toISODate() ?? '')
            }
            if (!IsNullOrUndefined(end)) {
                queryString.append('end', end.plus({ days: 1 }).toISODate() ?? '')
            }
            var url = `api/giardino/contract/search/${encodeURI(search)}`
            if (queryString.has('start') || queryString.has('end')) {
                url += `?${queryString.toString()}`
            }
            setIsLoading(true)
            var authResult = await getAccessToken(instance, accounts, inProgress)
            const response = await HTTP.GetData<{ success: boolean; message: string; totalPages: number; contracts: GiardinoContractSearchHttpResults[] }>(url, authResult?.accessToken)
            if (IsNullOrUndefined(response) || IsNullOrUndefined(response.contracts)) {
                setErrorMessage('Não foi possível buscar os contratos. Tente novamente.')
            } else if (response.contracts.length === 0) {
                setErrorMessage('Não foram encontrados contratos com os termos utilizados.')
            } else if (!response.success) {
                setErrorMessage(response.message)
            } else {
                setFirstSearch(true)
                setContractResults(
                    response?.contracts?.map(c => {
                        return { ...c, startDate: DateTime.fromISO(c.startDate), endDate: DateTime.fromISO(c.endDate) }
                    }) ?? []
                )
            }
        } catch (ex: any) {
            setErrorMessage(ex.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            {!IsStringNullOrWhitespace(errorMessage) && <FabDevDialogBox title="Erro durante requisição" message={errorMessage} />}
            {pdfViewerDialogOpen && contractPdfModal}
            {docxViewerDialogOpen && contractDocxModal}
            {editContractDialogOpen && editContractModal}
            {receiptDialogOpen && receiptModal}
            <Stack spacing={2} sx={{ mt: 2 }}>
                <Item>
                    <Stack>
                        <TextField sx={{ m: 1 }} variant="filled" label="Parte do nome, cpf, rg ou endereço (opcional)" value={searchParams.search} onChange={(elem: any) => handleSearchStringChange(elem.target.value)} />
                        <DatePicker
                            renderInput={(props: TextFieldProps) => <TextField sx={{ m: 1 }} variant="filled" {...props} />}
                            label="A partir de"
                            value={searchParams.start}
                            mask="__/__/____"
                            onChange={(newValue: DateTime | null, keyboardInputValue?: string | undefined) => handleSearchStartChange(newValue ?? undefined)}
                        />
                        <DatePicker
                            renderInput={(props: TextFieldProps) => <TextField sx={{ m: 1 }} variant="filled" {...props} />}
                            label="Até"
                            value={searchParams.end}
                            mask="__/__/____"
                            onChange={(newValue: DateTime | null, keyboardInputValue?: string | undefined) => handleSearchEndChange(newValue ?? undefined)}
                        />
                    </Stack>
                </Item>
                <Item>
                    <LoadingButton onClick={searchContracts} endIcon={<SendIcon />} loading={isLoading} loadingPosition="end" color="success" variant="contained">
                        Buscar contratos
                    </LoadingButton>
                </Item>
                {contractResults.length === 0 && !isLoading && hasFirstSearch && (
                    <Item>
                        <Typography>Nãos foram encontrados contratos com os termos buscados</Typography>
                    </Item>
                )}
                {contractResults.length > 0 && !isLoading && hasFirstSearch && (
                    <Item sx={{ flexGrow: 1, height: '100%' }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nome</TableCell>
                                        <TableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                            CPF
                                        </TableCell>
                                        <TableCell align="center" sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                                            Data entrada
                                        </TableCell>
                                        <TableCell align="center" sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                                            Data saída
                                        </TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contractResults.map((contract, i) => (
                                        <TableRow key={`findCont${i}`}>
                                            <TableCell align="left">{contract.fullName}</TableCell>
                                            <TableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                                {contract.cpf}
                                            </TableCell>
                                            <TableCell align="center" sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                                                {contract.startDate.toFormat('dd/MM/yyyy')}
                                            </TableCell>
                                            <TableCell align="center" sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                                                {contract.endDate.toFormat('dd/MM/yyyy')}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton size="large" aria-label="Navigation menu" aria-controls="menu-navbar" aria-haspopup="true" onClick={evt => handleActionsMenuOpen(i, evt)} color="inherit" sx={{ mr: 2 }}>
                                                    <ReceiptIcon />
                                                </IconButton>
                                                <Menu
                                                    id="menu-navbar"
                                                    anchorEl={anchorElemsContractActionsMenu[i]}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    open={Boolean(anchorElemsContractActionsMenu[i])}
                                                    onClose={evt => handleActionsMenuClose(i)}>
                                                    <MenuList>
                                                        <MenuItem
                                                            onClick={elem => {
                                                                handleActionsMenuClose(i)
                                                                setContractHash(contract.hash)
                                                                setContractName(contract.fullName)
                                                                setPdfViewerDialogOpen(true)
                                                            }}>
                                                            Contrato PDF
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={elem => {
                                                                handleActionsMenuClose(i)
                                                                setContractHash(contract.hash)
                                                                setContractName(contract.fullName)
                                                                setDocxViewerDialogOpen(true)
                                                            }}>
                                                            Contrato DOCX
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={elem => {
                                                                handleActionsMenuClose(i)
                                                                setContractHash(contract.hash)
                                                                setContractName(contract.fullName)
                                                                setEditContractDialogOpen(true)
                                                            }}>
                                                            Editar
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={elem => {
                                                                handleActionsMenuClose(i)
                                                                setReceiptDialogOpen(true)
                                                            }}>
                                                            Gerar recibo
                                                        </MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Item>
                )}
            </Stack>
        </>
    )
}
